<template>
  <vs-row vs-justify="center">
    <vs-col
      type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-xs="12"
    >
      <vs-card>
        <div class="material-icon-list-demo">
          <div
            id="icons"
            class="row icons"
          >
            <div class="m-icon">
              <i class="mr-2 mdi mdi-access-point" />
              <code class="mr-2">f002</code>
              <span>mdi-access-point</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-access-point-network" />
              <code class="mr-2">f003</code>
              <span>mdi-access-point-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account" />
              <code class="mr-2">f004</code>
              <span>mdi-account</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-alert" />
              <code class="mr-2">f005</code>
              <span>mdi-account-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-box" />
              <code class="mr-2">f006</code>
              <span>mdi-account-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-box-outline" />
              <code class="mr-2">f007</code>
              <span>mdi-account-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-card-details" />
              <code class="mr-2">f5d2</code>
              <span>mdi-account-card-details</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-check" />
              <code class="mr-2">f008</code>
              <span>mdi-account-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-circle" />
              <code class="mr-2">f009</code>
              <span>mdi-account-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-convert" />
              <code class="mr-2">f00a</code>
              <span>mdi-account-convert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-edit" />
              <code class="mr-2">f6bb</code>
              <span>mdi-account-edit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-key" />
              <code class="mr-2">f00b</code>
              <span>mdi-account-key</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-location" />
              <code class="mr-2">f00c</code>
              <span>mdi-account-location</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-minus" />
              <code class="mr-2">f00d</code>
              <span>mdi-account-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-multiple" />
              <code class="mr-2">f00e</code>
              <span>mdi-account-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-multiple-minus" />
              <code class="mr-2">f5d3</code>
              <span>mdi-account-multiple-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-multiple-outline" />
              <code class="mr-2">f00f</code>
              <span>mdi-account-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-multiple-plus" />
              <code class="mr-2">f010</code>
              <span>mdi-account-multiple-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-network" />
              <code class="mr-2">f011</code>
              <span>mdi-account-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-off" />
              <code class="mr-2">f012</code>
              <span>mdi-account-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-outline" />
              <code class="mr-2">f013</code>
              <span>mdi-account-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-plus" />
              <code class="mr-2">f014</code>
              <span>mdi-account-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-remove" />
              <code class="mr-2">f015</code>
              <span>mdi-account-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-search" />
              <code class="mr-2">f016</code>
              <span>mdi-account-search</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-settings" />
              <code class="mr-2">f630</code>
              <span>mdi-account-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-settings-variant" />
              <code class="mr-2">f631</code>
              <span>mdi-account-settings-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-star" />
              <code class="mr-2">f017</code>
              <span>mdi-account-star</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-star-variant" />
              <code class="mr-2">f018</code>
              <span>mdi-account-star-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-account-switch" />
              <code class="mr-2">f019</code>
              <span>mdi-account-switch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-adjust" />
              <code class="mr-2">f01a</code>
              <span>mdi-adjust</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-air-conditioner" />
              <code class="mr-2">f01b</code>
              <span>mdi-air-conditioner</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-airballoon" />
              <code class="mr-2">f01c</code>
              <span>mdi-airballoon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-airplane" />
              <code class="mr-2">f01d</code>
              <span>mdi-airplane</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-airplane-landing" />
              <code class="mr-2">f5d4</code>
              <span>mdi-airplane-landing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-airplane-off" />
              <code class="mr-2">f01e</code>
              <span>mdi-airplane-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-airplane-takeoff" />
              <code class="mr-2">f5d5</code>
              <span>mdi-airplane-takeoff</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-airplay" />
              <code class="mr-2">f01f</code>
              <span>mdi-airplay</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alarm" />
              <code class="mr-2">f020</code>
              <span>mdi-alarm</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alarm-check" />
              <code class="mr-2">f021</code>
              <span>mdi-alarm-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alarm-multiple" />
              <code class="mr-2">f022</code>
              <span>mdi-alarm-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alarm-off" />
              <code class="mr-2">f023</code>
              <span>mdi-alarm-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alarm-plus" />
              <code class="mr-2">f024</code>
              <span>mdi-alarm-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alarm-snooze" />
              <code class="mr-2">f68d</code>
              <span>mdi-alarm-snooze</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-album" />
              <code class="mr-2">f025</code>
              <span>mdi-album</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert" />
              <code class="mr-2">f026</code>
              <span>mdi-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert-box" />
              <code class="mr-2">f027</code>
              <span>mdi-alert-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert-circle" />
              <code class="mr-2">f028</code>
              <span>mdi-alert-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert-circle-outline" />
              <code class="mr-2">f5d6</code>
              <span>mdi-alert-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert-octagon" />
              <code class="mr-2">f029</code>
              <span>mdi-alert-octagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert-octagram" />
              <code class="mr-2">f6bc</code>
              <span>mdi-alert-octagram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alert-outline" />
              <code class="mr-2">f02a</code>
              <span>mdi-alert-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-all-inclusive" />
              <code class="mr-2">f6bd</code>
              <span>mdi-all-inclusive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alpha" />
              <code class="mr-2">f02b</code>
              <span>mdi-alpha</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-alphabetical" />
              <code class="mr-2">f02c</code>
              <span>mdi-alphabetical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-altimeter" />
              <code class="mr-2">f5d7</code>
              <span>mdi-altimeter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-amazon" />
              <code class="mr-2">f02d</code>
              <span>mdi-amazon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-amazon-clouddrive" />
              <code class="mr-2">f02e</code>
              <span>mdi-amazon-clouddrive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ambulance" />
              <code class="mr-2">f02f</code>
              <span>mdi-ambulance</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-amplifier" />
              <code class="mr-2">f030</code>
              <span>mdi-amplifier</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-anchor" />
              <code class="mr-2">f031</code>
              <span>mdi-anchor</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-android" />
              <code class="mr-2">f032</code>
              <span>mdi-android</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-android-debug-bridge" />
              <code class="mr-2">f033</code>
              <span>mdi-android-debug-bridge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-android-studio" />
              <code class="mr-2">f034</code>
              <span>mdi-android-studio</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-angular" />
              <code class="mr-2">f6b1</code>
              <span>mdi-angular</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-angularjs" />
              <code class="mr-2">f6be</code>
              <span>mdi-angularjs</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-animation" />
              <code class="mr-2">f5d8</code>
              <span>mdi-animation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple" />
              <code class="mr-2">f035</code>
              <span>mdi-apple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-finder" />
              <code class="mr-2">f036</code>
              <span>mdi-apple-finder</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-ios" />
              <code class="mr-2">f037</code>
              <span>mdi-apple-ios</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-keyboard-caps" />
              <code class="mr-2">f632</code>
              <span>mdi-apple-keyboard-caps</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-keyboard-command" />
              <code class="mr-2">f633</code>
              <span>mdi-apple-keyboard-command</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-keyboard-control" />
              <code class="mr-2">f634</code>
              <span>mdi-apple-keyboard-control</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-keyboard-option" />
              <code class="mr-2">f635</code>
              <span>mdi-apple-keyboard-option</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-keyboard-shift" />
              <code class="mr-2">f636</code>
              <span>mdi-apple-keyboard-shift</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-mobileme" />
              <code class="mr-2">f038</code>
              <span>mdi-apple-mobileme</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apple-safari" />
              <code class="mr-2">f039</code>
              <span>mdi-apple-safari</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-application" />
              <code class="mr-2">f614</code>
              <span>mdi-application</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-apps" />
              <code class="mr-2">f03b</code>
              <span>mdi-apps</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-archive" />
              <code class="mr-2">f03c</code>
              <span>mdi-archive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrange-bring-forward" />
              <code class="mr-2">f03d</code>
              <span>mdi-arrange-bring-forward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrange-bring-to-front" />
              <code class="mr-2">f03e</code>
              <span>mdi-arrange-bring-to-front</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrange-send-backward" />
              <code class="mr-2">f03f</code>
              <span>mdi-arrange-send-backward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrange-send-to-back" />
              <code class="mr-2">f040</code>
              <span>mdi-arrange-send-to-back</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-all" />
              <code class="mr-2">f041</code>
              <span>mdi-arrow-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-bottom-left" />
              <code class="mr-2">f042</code>
              <span>mdi-arrow-bottom-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-bottom-right" />
              <code class="mr-2">f043</code>
              <span>mdi-arrow-bottom-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-compress" />
              <code class="mr-2">f615</code>
              <span>mdi-arrow-compress</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-compress-all" />
              <code class="mr-2">f044</code>
              <span>mdi-arrow-compress-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down" />
              <code class="mr-2">f045</code>
              <span>mdi-arrow-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-bold" />
              <code class="mr-2">f046</code>
              <span>mdi-arrow-down-bold</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-bold-circle" />
              <code class="mr-2">f047</code>
              <span>mdi-arrow-down-bold-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-bold-circle-outline" />
              <code class="mr-2">f048</code>
              <span>mdi-arrow-down-bold-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-bold-hexagon-outline" />
              <code class="mr-2">f049</code>
              <span>mdi-arrow-down-bold-hexagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-box" />
              <code class="mr-2">f6bf</code>
              <span>mdi-arrow-down-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-drop-circle" />
              <code class="mr-2">f04a</code>
              <span>mdi-arrow-down-drop-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-down-drop-circle-outline" />
              <code class="mr-2">f04b</code>
              <span>mdi-arrow-down-drop-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-expand" />
              <code class="mr-2">f616</code>
              <span>mdi-arrow-expand</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-expand-all" />
              <code class="mr-2">f04c</code>
              <span>mdi-arrow-expand-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left" />
              <code class="mr-2">f04d</code>
              <span>mdi-arrow-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-bold" />
              <code class="mr-2">f04e</code>
              <span>mdi-arrow-left-bold</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-bold-circle" />
              <code class="mr-2">f04f</code>
              <span>mdi-arrow-left-bold-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-bold-circle-outline" />
              <code class="mr-2">f050</code>
              <span>mdi-arrow-left-bold-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-bold-hexagon-outline" />
              <code class="mr-2">f051</code>
              <span>mdi-arrow-left-bold-hexagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-box" />
              <code class="mr-2">f6c0</code>
              <span>mdi-arrow-left-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-drop-circle" />
              <code class="mr-2">f052</code>
              <span>mdi-arrow-left-drop-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-left-drop-circle-outline" />
              <code class="mr-2">f053</code>
              <span>mdi-arrow-left-drop-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right" />
              <code class="mr-2">f054</code>
              <span>mdi-arrow-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-bold" />
              <code class="mr-2">f055</code>
              <span>mdi-arrow-right-bold</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-bold-circle" />
              <code class="mr-2">f056</code>
              <span>mdi-arrow-right-bold-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-bold-circle-outline" />
              <code class="mr-2">f057</code>
              <span>mdi-arrow-right-bold-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-bold-hexagon-outline" />
              <code class="mr-2">f058</code>
              <span>mdi-arrow-right-bold-hexagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-box" />
              <code class="mr-2">f6c1</code>
              <span>mdi-arrow-right-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-drop-circle" />
              <code class="mr-2">f059</code>
              <span>mdi-arrow-right-drop-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-right-drop-circle-outline" />
              <code class="mr-2">f05a</code>
              <span>mdi-arrow-right-drop-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-top-left" />
              <code class="mr-2">f05b</code>
              <span>mdi-arrow-top-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-top-right" />
              <code class="mr-2">f05c</code>
              <span>mdi-arrow-top-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up" />
              <code class="mr-2">f05d</code>
              <span>mdi-arrow-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-bold" />
              <code class="mr-2">f05e</code>
              <span>mdi-arrow-up-bold</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-bold-circle" />
              <code class="mr-2">f05f</code>
              <span>mdi-arrow-up-bold-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-bold-circle-outline" />
              <code class="mr-2">f060</code>
              <span>mdi-arrow-up-bold-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-bold-hexagon-outline" />
              <code class="mr-2">f061</code>
              <span>mdi-arrow-up-bold-hexagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-box" />
              <code class="mr-2">f6c2</code>
              <span>mdi-arrow-up-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-drop-circle" />
              <code class="mr-2">f062</code>
              <span>mdi-arrow-up-drop-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-arrow-up-drop-circle-outline" />
              <code class="mr-2">f063</code>
              <span>mdi-arrow-up-drop-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-assistant" />
              <code class="mr-2">f064</code>
              <span>mdi-assistant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-asterisk" />
              <code class="mr-2">f6c3</code>
              <span>mdi-asterisk</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-at" />
              <code class="mr-2">f065</code>
              <span>mdi-at</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-attachment" />
              <code class="mr-2">f066</code>
              <span>mdi-attachment</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-audiobook" />
              <code class="mr-2">f067</code>
              <span>mdi-audiobook</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-auto-fix" />
              <code class="mr-2">f068</code>
              <span>mdi-auto-fix</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-auto-upload" />
              <code class="mr-2">f069</code>
              <span>mdi-auto-upload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-autorenew" />
              <code class="mr-2">f06a</code>
              <span>mdi-autorenew</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-av-timer" />
              <code class="mr-2">f06b</code>
              <span>mdi-av-timer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-baby" />
              <code class="mr-2">f06c</code>
              <span>mdi-baby</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-baby-buggy" />
              <code class="mr-2">f68e</code>
              <span>mdi-baby-buggy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-backburger" />
              <code class="mr-2">f06d</code>
              <span>mdi-backburger</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-backspace" />
              <code class="mr-2">f06e</code>
              <span>mdi-backspace</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-backup-restore" />
              <code class="mr-2">f06f</code>
              <span>mdi-backup-restore</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bandcamp" />
              <code class="mr-2">f674</code>
              <span>mdi-bandcamp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bank" />
              <code class="mr-2">f070</code>
              <span>mdi-bank</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-barcode" />
              <code class="mr-2">f071</code>
              <span>mdi-barcode</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-barcode-scan" />
              <code class="mr-2">f072</code>
              <span>mdi-barcode-scan</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-barley" />
              <code class="mr-2">f073</code>
              <span>mdi-barley</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-barrel" />
              <code class="mr-2">f074</code>
              <span>mdi-barrel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-basecamp" />
              <code class="mr-2">f075</code>
              <span>mdi-basecamp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-basket" />
              <code class="mr-2">f076</code>
              <span>mdi-basket</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-basket-fill" />
              <code class="mr-2">f077</code>
              <span>mdi-basket-fill</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-basket-unfill" />
              <code class="mr-2">f078</code>
              <span>mdi-basket-unfill</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery" />
              <code class="mr-2">f079</code>
              <span>mdi-battery</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-10" />
              <code class="mr-2">f07a</code>
              <span>mdi-battery-10</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-20" />
              <code class="mr-2">f07b</code>
              <span>mdi-battery-20</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-30" />
              <code class="mr-2">f07c</code>
              <span>mdi-battery-30</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-40" />
              <code class="mr-2">f07d</code>
              <span>mdi-battery-40</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-50" />
              <code class="mr-2">f07e</code>
              <span>mdi-battery-50</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-60" />
              <code class="mr-2">f07f</code>
              <span>mdi-battery-60</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-70" />
              <code class="mr-2">f080</code>
              <span>mdi-battery-70</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-80" />
              <code class="mr-2">f081</code>
              <span>mdi-battery-80</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-90" />
              <code class="mr-2">f082</code>
              <span>mdi-battery-90</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-alert" />
              <code class="mr-2">f083</code>
              <span>mdi-battery-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging" />
              <code class="mr-2">f084</code>
              <span>mdi-battery-charging</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-100" />
              <code class="mr-2">f085</code>
              <span>mdi-battery-charging-100</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-20" />
              <code class="mr-2">f086</code>
              <span>mdi-battery-charging-20</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-30" />
              <code class="mr-2">f087</code>
              <span>mdi-battery-charging-30</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-40" />
              <code class="mr-2">f088</code>
              <span>mdi-battery-charging-40</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-60" />
              <code class="mr-2">f089</code>
              <span>mdi-battery-charging-60</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-80" />
              <code class="mr-2">f08a</code>
              <span>mdi-battery-charging-80</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-charging-90" />
              <code class="mr-2">f08b</code>
              <span>mdi-battery-charging-90</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-minus" />
              <code class="mr-2">f08c</code>
              <span>mdi-battery-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-negative" />
              <code class="mr-2">f08d</code>
              <span>mdi-battery-negative</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-outline" />
              <code class="mr-2">f08e</code>
              <span>mdi-battery-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-plus" />
              <code class="mr-2">f08f</code>
              <span>mdi-battery-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-positive" />
              <code class="mr-2">f090</code>
              <span>mdi-battery-positive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-battery-unknown" />
              <code class="mr-2">f091</code>
              <span>mdi-battery-unknown</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-beach" />
              <code class="mr-2">f092</code>
              <span>mdi-beach</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-beaker" />
              <code class="mr-2">f68f</code>
              <span>mdi-beaker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-beats" />
              <code class="mr-2">f097</code>
              <span>mdi-beats</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-beer" />
              <code class="mr-2">f098</code>
              <span>mdi-beer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-behance" />
              <code class="mr-2">f099</code>
              <span>mdi-behance</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell" />
              <code class="mr-2">f09a</code>
              <span>mdi-bell</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell-off" />
              <code class="mr-2">f09b</code>
              <span>mdi-bell-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell-outline" />
              <code class="mr-2">f09c</code>
              <span>mdi-bell-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell-plus" />
              <code class="mr-2">f09d</code>
              <span>mdi-bell-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell-ring" />
              <code class="mr-2">f09e</code>
              <span>mdi-bell-ring</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell-ring-outline" />
              <code class="mr-2">f09f</code>
              <span>mdi-bell-ring-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bell-sleep" />
              <code class="mr-2">f0a0</code>
              <span>mdi-bell-sleep</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-beta" />
              <code class="mr-2">f0a1</code>
              <span>mdi-beta</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bible" />
              <code class="mr-2">f0a2</code>
              <span>mdi-bible</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bike" />
              <code class="mr-2">f0a3</code>
              <span>mdi-bike</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bing" />
              <code class="mr-2">f0a4</code>
              <span>mdi-bing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-binoculars" />
              <code class="mr-2">f0a5</code>
              <span>mdi-binoculars</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bio" />
              <code class="mr-2">f0a6</code>
              <span>mdi-bio</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-biohazard" />
              <code class="mr-2">f0a7</code>
              <span>mdi-biohazard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bitbucket" />
              <code class="mr-2">f0a8</code>
              <span>mdi-bitbucket</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-black-mesa" />
              <code class="mr-2">f0a9</code>
              <span>mdi-black-mesa</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blackberry" />
              <code class="mr-2">f0aa</code>
              <span>mdi-blackberry</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blender" />
              <code class="mr-2">f0ab</code>
              <span>mdi-blender</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blinds" />
              <code class="mr-2">f0ac</code>
              <span>mdi-blinds</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-block-helper" />
              <code class="mr-2">f0ad</code>
              <span>mdi-block-helper</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blogger" />
              <code class="mr-2">f0ae</code>
              <span>mdi-blogger</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bluetooth" />
              <code class="mr-2">f0af</code>
              <span>mdi-bluetooth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bluetooth-audio" />
              <code class="mr-2">f0b0</code>
              <span>mdi-bluetooth-audio</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bluetooth-connect" />
              <code class="mr-2">f0b1</code>
              <span>mdi-bluetooth-connect</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bluetooth-off" />
              <code class="mr-2">f0b2</code>
              <span>mdi-bluetooth-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bluetooth-settings" />
              <code class="mr-2">f0b3</code>
              <span>mdi-bluetooth-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bluetooth-transfer" />
              <code class="mr-2">f0b4</code>
              <span>mdi-bluetooth-transfer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blur" />
              <code class="mr-2">f0b5</code>
              <span>mdi-blur</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blur-linear" />
              <code class="mr-2">f0b6</code>
              <span>mdi-blur-linear</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blur-off" />
              <code class="mr-2">f0b7</code>
              <span>mdi-blur-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-blur-radial" />
              <code class="mr-2">f0b8</code>
              <span>mdi-blur-radial</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bomb" />
              <code class="mr-2">f690</code>
              <span>mdi-bomb</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bomb-off" />
              <code class="mr-2">f6c4</code>
              <span>mdi-bomb-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bone" />
              <code class="mr-2">f0b9</code>
              <span>mdi-bone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book" />
              <code class="mr-2">f0ba</code>
              <span>mdi-book</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-minus" />
              <code class="mr-2">f5d9</code>
              <span>mdi-book-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-multiple" />
              <code class="mr-2">f0bb</code>
              <span>mdi-book-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-multiple-variant" />
              <code class="mr-2">f0bc</code>
              <span>mdi-book-multiple-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-open" />
              <code class="mr-2">f0bd</code>
              <span>mdi-book-open</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-open-page-variant" />
              <code class="mr-2">f5da</code>
              <span>mdi-book-open-page-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-open-variant" />
              <code class="mr-2">f0be</code>
              <span>mdi-book-open-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-plus" />
              <code class="mr-2">f5db</code>
              <span>mdi-book-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-book-variant" />
              <code class="mr-2">f0bf</code>
              <span>mdi-book-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark" />
              <code class="mr-2">f0c0</code>
              <span>mdi-bookmark</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark-check" />
              <code class="mr-2">f0c1</code>
              <span>mdi-bookmark-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark-music" />
              <code class="mr-2">f0c2</code>
              <span>mdi-bookmark-music</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark-outline" />
              <code class="mr-2">f0c3</code>
              <span>mdi-bookmark-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark-plus" />
              <code class="mr-2">f0c5</code>
              <span>mdi-bookmark-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark-plus-outline" />
              <code class="mr-2">f0c4</code>
              <span>mdi-bookmark-plus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bookmark-remove" />
              <code class="mr-2">f0c6</code>
              <span>mdi-bookmark-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-boombox" />
              <code class="mr-2">f5dc</code>
              <span>mdi-boombox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bootstrap" />
              <code class="mr-2">f6c5</code>
              <span>mdi-bootstrap</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-all" />
              <code class="mr-2">f0c7</code>
              <span>mdi-border-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-bottom" />
              <code class="mr-2">f0c8</code>
              <span>mdi-border-bottom</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-color" />
              <code class="mr-2">f0c9</code>
              <span>mdi-border-color</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-horizontal" />
              <code class="mr-2">f0ca</code>
              <span>mdi-border-horizontal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-inside" />
              <code class="mr-2">f0cb</code>
              <span>mdi-border-inside</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-left" />
              <code class="mr-2">f0cc</code>
              <span>mdi-border-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-none" />
              <code class="mr-2">f0cd</code>
              <span>mdi-border-none</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-outside" />
              <code class="mr-2">f0ce</code>
              <span>mdi-border-outside</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-right" />
              <code class="mr-2">f0cf</code>
              <span>mdi-border-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-style" />
              <code class="mr-2">f0d0</code>
              <span>mdi-border-style</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-top" />
              <code class="mr-2">f0d1</code>
              <span>mdi-border-top</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-border-vertical" />
              <code class="mr-2">f0d2</code>
              <span>mdi-border-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bow-tie" />
              <code class="mr-2">f677</code>
              <span>mdi-bow-tie</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bowl" />
              <code class="mr-2">f617</code>
              <span>mdi-bowl</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bowling" />
              <code class="mr-2">f0d3</code>
              <span>mdi-bowling</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-box" />
              <code class="mr-2">f0d4</code>
              <span>mdi-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-box-cutter" />
              <code class="mr-2">f0d5</code>
              <span>mdi-box-cutter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-box-shadow" />
              <code class="mr-2">f637</code>
              <span>mdi-box-shadow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bridge" />
              <code class="mr-2">f618</code>
              <span>mdi-bridge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-briefcase" />
              <code class="mr-2">f0d6</code>
              <span>mdi-briefcase</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-briefcase-check" />
              <code class="mr-2">f0d7</code>
              <span>mdi-briefcase-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-briefcase-download" />
              <code class="mr-2">f0d8</code>
              <span>mdi-briefcase-download</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-briefcase-upload" />
              <code class="mr-2">f0d9</code>
              <span>mdi-briefcase-upload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-1" />
              <code class="mr-2">f0da</code>
              <span>mdi-brightness-1</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-2" />
              <code class="mr-2">f0db</code>
              <span>mdi-brightness-2</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-3" />
              <code class="mr-2">f0dc</code>
              <span>mdi-brightness-3</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-4" />
              <code class="mr-2">f0dd</code>
              <span>mdi-brightness-4</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-5" />
              <code class="mr-2">f0de</code>
              <span>mdi-brightness-5</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-6" />
              <code class="mr-2">f0df</code>
              <span>mdi-brightness-6</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-7" />
              <code class="mr-2">f0e0</code>
              <span>mdi-brightness-7</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brightness-auto" />
              <code class="mr-2">f0e1</code>
              <span>mdi-brightness-auto</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-broom" />
              <code class="mr-2">f0e2</code>
              <span>mdi-broom</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-brush" />
              <code class="mr-2">f0e3</code>
              <span>mdi-brush</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-buffer" />
              <code class="mr-2">f619</code>
              <span>mdi-buffer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bug" />
              <code class="mr-2">f0e4</code>
              <span>mdi-bug</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bulletin-board" />
              <code class="mr-2">f0e5</code>
              <span>mdi-bulletin-board</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bullhorn" />
              <code class="mr-2">f0e6</code>
              <span>mdi-bullhorn</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bullseye" />
              <code class="mr-2">f5dd</code>
              <span>mdi-bullseye</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-burst-mode" />
              <code class="mr-2">f5de</code>
              <span>mdi-burst-mode</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-bus" />
              <code class="mr-2">f0e7</code>
              <span>mdi-bus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cached" />
              <code class="mr-2">f0e8</code>
              <span>mdi-cached</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cake" />
              <code class="mr-2">f0e9</code>
              <span>mdi-cake</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cake-layered" />
              <code class="mr-2">f0ea</code>
              <span>mdi-cake-layered</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cake-variant" />
              <code class="mr-2">f0eb</code>
              <span>mdi-cake-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calculator" />
              <code class="mr-2">f0ec</code>
              <span>mdi-calculator</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar" />
              <code class="mr-2">f0ed</code>
              <span>mdi-calendar</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-blank" />
              <code class="mr-2">f0ee</code>
              <span>mdi-calendar-blank</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-check" />
              <code class="mr-2">f0ef</code>
              <span>mdi-calendar-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-clock" />
              <code class="mr-2">f0f0</code>
              <span>mdi-calendar-clock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-multiple" />
              <code class="mr-2">f0f1</code>
              <span>mdi-calendar-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-multiple-check" />
              <code class="mr-2">f0f2</code>
              <span>mdi-calendar-multiple-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-plus" />
              <code class="mr-2">f0f3</code>
              <span>mdi-calendar-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-question" />
              <code class="mr-2">f691</code>
              <span>mdi-calendar-question</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-range" />
              <code class="mr-2">f678</code>
              <span>mdi-calendar-range</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-remove" />
              <code class="mr-2">f0f4</code>
              <span>mdi-calendar-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-text" />
              <code class="mr-2">f0f5</code>
              <span>mdi-calendar-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-calendar-today" />
              <code class="mr-2">f0f6</code>
              <span>mdi-calendar-today</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-call-made" />
              <code class="mr-2">f0f7</code>
              <span>mdi-call-made</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-call-merge" />
              <code class="mr-2">f0f8</code>
              <span>mdi-call-merge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-call-missed" />
              <code class="mr-2">f0f9</code>
              <span>mdi-call-missed</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-call-received" />
              <code class="mr-2">f0fa</code>
              <span>mdi-call-received</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-call-split" />
              <code class="mr-2">f0fb</code>
              <span>mdi-call-split</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camcorder" />
              <code class="mr-2">f0fc</code>
              <span>mdi-camcorder</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camcorder-box" />
              <code class="mr-2">f0fd</code>
              <span>mdi-camcorder-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camcorder-box-off" />
              <code class="mr-2">f0fe</code>
              <span>mdi-camcorder-box-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camcorder-off" />
              <code class="mr-2">f0ff</code>
              <span>mdi-camcorder-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera" />
              <code class="mr-2">f100</code>
              <span>mdi-camera</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-burst" />
              <code class="mr-2">f692</code>
              <span>mdi-camera-burst</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-enhance" />
              <code class="mr-2">f101</code>
              <span>mdi-camera-enhance</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-front" />
              <code class="mr-2">f102</code>
              <span>mdi-camera-front</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-front-variant" />
              <code class="mr-2">f103</code>
              <span>mdi-camera-front-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-iris" />
              <code class="mr-2">f104</code>
              <span>mdi-camera-iris</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-off" />
              <code class="mr-2">f5df</code>
              <span>mdi-camera-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-party-mode" />
              <code class="mr-2">f105</code>
              <span>mdi-camera-party-mode</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-rear" />
              <code class="mr-2">f106</code>
              <span>mdi-camera-rear</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-rear-variant" />
              <code class="mr-2">f107</code>
              <span>mdi-camera-rear-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-switch" />
              <code class="mr-2">f108</code>
              <span>mdi-camera-switch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-camera-timer" />
              <code class="mr-2">f109</code>
              <span>mdi-camera-timer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-candle" />
              <code class="mr-2">f5e2</code>
              <span>mdi-candle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-candycane" />
              <code class="mr-2">f10a</code>
              <span>mdi-candycane</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-car" />
              <code class="mr-2">f10b</code>
              <span>mdi-car</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-car-battery" />
              <code class="mr-2">f10c</code>
              <span>mdi-car-battery</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-car-connected" />
              <code class="mr-2">f10d</code>
              <span>mdi-car-connected</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-car-wash" />
              <code class="mr-2">f10e</code>
              <span>mdi-car-wash</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cards" />
              <code class="mr-2">f638</code>
              <span>mdi-cards</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cards-outline" />
              <code class="mr-2">f639</code>
              <span>mdi-cards-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cards-playing-outline" />
              <code class="mr-2">f63a</code>
              <span>mdi-cards-playing-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cards-variant" />
              <code class="mr-2">f6c6</code>
              <span>mdi-cards-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-carrot" />
              <code class="mr-2">f10f</code>
              <span>mdi-carrot</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cart" />
              <code class="mr-2">f110</code>
              <span>mdi-cart</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cart-off" />
              <code class="mr-2">f66b</code>
              <span>mdi-cart-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cart-outline" />
              <code class="mr-2">f111</code>
              <span>mdi-cart-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cart-plus" />
              <code class="mr-2">f112</code>
              <span>mdi-cart-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-case-sensitive-alt" />
              <code class="mr-2">f113</code>
              <span>mdi-case-sensitive-alt</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cash" />
              <code class="mr-2">f114</code>
              <span>mdi-cash</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cash-100" />
              <code class="mr-2">f115</code>
              <span>mdi-cash-100</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cash-multiple" />
              <code class="mr-2">f116</code>
              <span>mdi-cash-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cash-usd" />
              <code class="mr-2">f117</code>
              <span>mdi-cash-usd</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cast" />
              <code class="mr-2">f118</code>
              <span>mdi-cast</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cast-connected" />
              <code class="mr-2">f119</code>
              <span>mdi-cast-connected</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-castle" />
              <code class="mr-2">f11a</code>
              <span>mdi-castle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cat" />
              <code class="mr-2">f11b</code>
              <span>mdi-cat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone" />
              <code class="mr-2">f11c</code>
              <span>mdi-cellphone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-android" />
              <code class="mr-2">f11d</code>
              <span>mdi-cellphone-android</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-basic" />
              <code class="mr-2">f11e</code>
              <span>mdi-cellphone-basic</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-dock" />
              <code class="mr-2">f11f</code>
              <span>mdi-cellphone-dock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-iphone" />
              <code class="mr-2">f120</code>
              <span>mdi-cellphone-iphone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-link" />
              <code class="mr-2">f121</code>
              <span>mdi-cellphone-link</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-link-off" />
              <code class="mr-2">f122</code>
              <span>mdi-cellphone-link-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cellphone-settings" />
              <code class="mr-2">f123</code>
              <span>mdi-cellphone-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-certificate" />
              <code class="mr-2">f124</code>
              <span>mdi-certificate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chair-school" />
              <code class="mr-2">f125</code>
              <span>mdi-chair-school</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-arc" />
              <code class="mr-2">f126</code>
              <span>mdi-chart-arc</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-areaspline" />
              <code class="mr-2">f127</code>
              <span>mdi-chart-areaspline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-bar" />
              <code class="mr-2">f128</code>
              <span>mdi-chart-bar</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-bubble" />
              <code class="mr-2">f5e3</code>
              <span>mdi-chart-bubble</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-gantt" />
              <code class="mr-2">f66c</code>
              <span>mdi-chart-gantt</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-histogram" />
              <code class="mr-2">f129</code>
              <span>mdi-chart-histogram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-line" />
              <code class="mr-2">f12a</code>
              <span>mdi-chart-line</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-pie" />
              <code class="mr-2">f12b</code>
              <span>mdi-chart-pie</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-scatterplot-hexbin" />
              <code class="mr-2">f66d</code>
              <span>mdi-chart-scatterplot-hexbin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chart-timeline" />
              <code class="mr-2">f66e</code>
              <span>mdi-chart-timeline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-check" />
              <code class="mr-2">f12c</code>
              <span>mdi-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-check-all" />
              <code class="mr-2">f12d</code>
              <span>mdi-check-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-check-circle" />
              <code class="mr-2">f5e0</code>
              <span>mdi-check-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-check-circle-outline" />
              <code class="mr-2">f5e1</code>
              <span>mdi-check-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-blank" />
              <code class="mr-2">f12e</code>
              <span>mdi-checkbox-blank</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-blank-circle" />
              <code class="mr-2">f12f</code>
              <span>mdi-checkbox-blank-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-blank-circle-outline" />
              <code class="mr-2">f130</code>
              <span>mdi-checkbox-blank-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-blank-outline" />
              <code class="mr-2">f131</code>
              <span>mdi-checkbox-blank-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-marked" />
              <code class="mr-2">f132</code>
              <span>mdi-checkbox-marked</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-marked-circle" />
              <code class="mr-2">f133</code>
              <span>mdi-checkbox-marked-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-marked-circle-outline" />
              <code class="mr-2">f134</code>
              <span>mdi-checkbox-marked-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-marked-outline" />
              <code class="mr-2">f135</code>
              <span>mdi-checkbox-marked-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-blank" />
              <code class="mr-2">f136</code>
              <span>mdi-checkbox-multiple-blank</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-blank-circle" />
              <code class="mr-2">f63b</code>
              <span>mdi-checkbox-multiple-blank-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-blank-circle-outline" />
              <code class="mr-2">f63c</code>
              <span>mdi-checkbox-multiple-blank-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-blank-outline" />
              <code class="mr-2">f137</code>
              <span>mdi-checkbox-multiple-blank-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-marked" />
              <code class="mr-2">f138</code>
              <span>mdi-checkbox-multiple-marked</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-marked-circle" />
              <code class="mr-2">f63d</code>
              <span>mdi-checkbox-multiple-marked-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-marked-circle-outline" />
              <code class="mr-2">f63e</code>
              <span>mdi-checkbox-multiple-marked-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkbox-multiple-marked-outline" />
              <code class="mr-2">f139</code>
              <span>mdi-checkbox-multiple-marked-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-checkerboard" />
              <code class="mr-2">f13a</code>
              <span>mdi-checkerboard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chemical-weapon" />
              <code class="mr-2">f13b</code>
              <span>mdi-chemical-weapon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-double-down" />
              <code class="mr-2">f13c</code>
              <span>mdi-chevron-double-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-double-left" />
              <code class="mr-2">f13d</code>
              <span>mdi-chevron-double-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-double-right" />
              <code class="mr-2">f13e</code>
              <span>mdi-chevron-double-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-double-up" />
              <code class="mr-2">f13f</code>
              <span>mdi-chevron-double-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-down" />
              <code class="mr-2">f140</code>
              <span>mdi-chevron-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-left" />
              <code class="mr-2">f141</code>
              <span>mdi-chevron-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-right" />
              <code class="mr-2">f142</code>
              <span>mdi-chevron-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chevron-up" />
              <code class="mr-2">f143</code>
              <span>mdi-chevron-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-chip" />
              <code class="mr-2">f61a</code>
              <span>mdi-chip</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-church" />
              <code class="mr-2">f144</code>
              <span>mdi-church</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cisco-webex" />
              <code class="mr-2">f145</code>
              <span>mdi-cisco-webex</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-city" />
              <code class="mr-2">f146</code>
              <span>mdi-city</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard" />
              <code class="mr-2">f147</code>
              <span>mdi-clipboard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-account" />
              <code class="mr-2">f148</code>
              <span>mdi-clipboard-account</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-alert" />
              <code class="mr-2">f149</code>
              <span>mdi-clipboard-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-arrow-down" />
              <code class="mr-2">f14a</code>
              <span>mdi-clipboard-arrow-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-arrow-left" />
              <code class="mr-2">f14b</code>
              <span>mdi-clipboard-arrow-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-check" />
              <code class="mr-2">f14c</code>
              <span>mdi-clipboard-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-flow" />
              <code class="mr-2">f6c7</code>
              <span>mdi-clipboard-flow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-outline" />
              <code class="mr-2">f14d</code>
              <span>mdi-clipboard-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clipboard-text" />
              <code class="mr-2">f14e</code>
              <span>mdi-clipboard-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clippy" />
              <code class="mr-2">f14f</code>
              <span>mdi-clippy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock" />
              <code class="mr-2">f150</code>
              <span>mdi-clock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock-alert" />
              <code class="mr-2">f5ce</code>
              <span>mdi-clock-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock-end" />
              <code class="mr-2">f151</code>
              <span>mdi-clock-end</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock-fast" />
              <code class="mr-2">f152</code>
              <span>mdi-clock-fast</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock-in" />
              <code class="mr-2">f153</code>
              <span>mdi-clock-in</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock-out" />
              <code class="mr-2">f154</code>
              <span>mdi-clock-out</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-clock-start" />
              <code class="mr-2">f155</code>
              <span>mdi-clock-start</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close" />
              <code class="mr-2">f156</code>
              <span>mdi-close</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-box" />
              <code class="mr-2">f157</code>
              <span>mdi-close-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-box-outline" />
              <code class="mr-2">f158</code>
              <span>mdi-close-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-circle" />
              <code class="mr-2">f159</code>
              <span>mdi-close-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-circle-outline" />
              <code class="mr-2">f15a</code>
              <span>mdi-close-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-network" />
              <code class="mr-2">f15b</code>
              <span>mdi-close-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-octagon" />
              <code class="mr-2">f15c</code>
              <span>mdi-close-octagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-octagon-outline" />
              <code class="mr-2">f15d</code>
              <span>mdi-close-octagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-close-outline" />
              <code class="mr-2">f6c8</code>
              <span>mdi-close-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-closed-caption" />
              <code class="mr-2">f15e</code>
              <span>mdi-closed-caption</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud" />
              <code class="mr-2">f15f</code>
              <span>mdi-cloud</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-check" />
              <code class="mr-2">f160</code>
              <span>mdi-cloud-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-circle" />
              <code class="mr-2">f161</code>
              <span>mdi-cloud-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-download" />
              <code class="mr-2">f162</code>
              <span>mdi-cloud-download</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-outline" />
              <code class="mr-2">f163</code>
              <span>mdi-cloud-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-outline-off" />
              <code class="mr-2">f164</code>
              <span>mdi-cloud-outline-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-print" />
              <code class="mr-2">f165</code>
              <span>mdi-cloud-print</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-print-outline" />
              <code class="mr-2">f166</code>
              <span>mdi-cloud-print-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-sync" />
              <code class="mr-2">f63f</code>
              <span>mdi-cloud-sync</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cloud-upload" />
              <code class="mr-2">f167</code>
              <span>mdi-cloud-upload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-array" />
              <code class="mr-2">f168</code>
              <span>mdi-code-array</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-braces" />
              <code class="mr-2">f169</code>
              <span>mdi-code-braces</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-brackets" />
              <code class="mr-2">f16a</code>
              <span>mdi-code-brackets</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-equal" />
              <code class="mr-2">f16b</code>
              <span>mdi-code-equal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-greater-than" />
              <code class="mr-2">f16c</code>
              <span>mdi-code-greater-than</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-greater-than-or-equal" />
              <code class="mr-2">f16d</code>
              <span>mdi-code-greater-than-or-equal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-less-than" />
              <code class="mr-2">f16e</code>
              <span>mdi-code-less-than</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-less-than-or-equal" />
              <code class="mr-2">f16f</code>
              <span>mdi-code-less-than-or-equal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-not-equal" />
              <code class="mr-2">f170</code>
              <span>mdi-code-not-equal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-not-equal-variant" />
              <code class="mr-2">f171</code>
              <span>mdi-code-not-equal-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-parentheses" />
              <code class="mr-2">f172</code>
              <span>mdi-code-parentheses</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-string" />
              <code class="mr-2">f173</code>
              <span>mdi-code-string</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-tags" />
              <code class="mr-2">f174</code>
              <span>mdi-code-tags</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-code-tags-check" />
              <code class="mr-2">f693</code>
              <span>mdi-code-tags-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-codepen" />
              <code class="mr-2">f175</code>
              <span>mdi-codepen</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-coffee" />
              <code class="mr-2">f176</code>
              <span>mdi-coffee</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-coffee-outline" />
              <code class="mr-2">f6c9</code>
              <span>mdi-coffee-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-coffee-to-go" />
              <code class="mr-2">f177</code>
              <span>mdi-coffee-to-go</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-coin" />
              <code class="mr-2">f178</code>
              <span>mdi-coin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-coins" />
              <code class="mr-2">f694</code>
              <span>mdi-coins</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-collage" />
              <code class="mr-2">f640</code>
              <span>mdi-collage</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-color-helper" />
              <code class="mr-2">f179</code>
              <span>mdi-color-helper</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment" />
              <code class="mr-2">f17a</code>
              <span>mdi-comment</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-account" />
              <code class="mr-2">f17b</code>
              <span>mdi-comment-account</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-account-outline" />
              <code class="mr-2">f17c</code>
              <span>mdi-comment-account-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-alert" />
              <code class="mr-2">f17d</code>
              <span>mdi-comment-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-alert-outline" />
              <code class="mr-2">f17e</code>
              <span>mdi-comment-alert-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-check" />
              <code class="mr-2">f17f</code>
              <span>mdi-comment-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-check-outline" />
              <code class="mr-2">f180</code>
              <span>mdi-comment-check-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-multiple-outline" />
              <code class="mr-2">f181</code>
              <span>mdi-comment-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-outline" />
              <code class="mr-2">f182</code>
              <span>mdi-comment-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-plus-outline" />
              <code class="mr-2">f183</code>
              <span>mdi-comment-plus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-processing" />
              <code class="mr-2">f184</code>
              <span>mdi-comment-processing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-processing-outline" />
              <code class="mr-2">f185</code>
              <span>mdi-comment-processing-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-question-outline" />
              <code class="mr-2">f186</code>
              <span>mdi-comment-question-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-remove-outline" />
              <code class="mr-2">f187</code>
              <span>mdi-comment-remove-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-text" />
              <code class="mr-2">f188</code>
              <span>mdi-comment-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-comment-text-outline" />
              <code class="mr-2">f189</code>
              <span>mdi-comment-text-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-compare" />
              <code class="mr-2">f18a</code>
              <span>mdi-compare</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-compass" />
              <code class="mr-2">f18b</code>
              <span>mdi-compass</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-compass-outline" />
              <code class="mr-2">f18c</code>
              <span>mdi-compass-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-console" />
              <code class="mr-2">f18d</code>
              <span>mdi-console</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-contact-mail" />
              <code class="mr-2">f18e</code>
              <span>mdi-contact-mail</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-contacts" />
              <code class="mr-2">f6ca</code>
              <span>mdi-contacts</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-copy" />
              <code class="mr-2">f18f</code>
              <span>mdi-content-copy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-cut" />
              <code class="mr-2">f190</code>
              <span>mdi-content-cut</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-duplicate" />
              <code class="mr-2">f191</code>
              <span>mdi-content-duplicate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-paste" />
              <code class="mr-2">f192</code>
              <span>mdi-content-paste</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-save" />
              <code class="mr-2">f193</code>
              <span>mdi-content-save</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-save-all" />
              <code class="mr-2">f194</code>
              <span>mdi-content-save-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-content-save-settings" />
              <code class="mr-2">f61b</code>
              <span>mdi-content-save-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-contrast" />
              <code class="mr-2">f195</code>
              <span>mdi-contrast</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-contrast-box" />
              <code class="mr-2">f196</code>
              <span>mdi-contrast-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-contrast-circle" />
              <code class="mr-2">f197</code>
              <span>mdi-contrast-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cookie" />
              <code class="mr-2">f198</code>
              <span>mdi-cookie</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-copyright" />
              <code class="mr-2">f5e6</code>
              <span>mdi-copyright</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-counter" />
              <code class="mr-2">f199</code>
              <span>mdi-counter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cow" />
              <code class="mr-2">f19a</code>
              <span>mdi-cow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-creation" />
              <code class="mr-2">f1c9</code>
              <span>mdi-creation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-credit-card" />
              <code class="mr-2">f19b</code>
              <span>mdi-credit-card</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-credit-card-multiple" />
              <code class="mr-2">f19c</code>
              <span>mdi-credit-card-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-credit-card-off" />
              <code class="mr-2">f5e4</code>
              <span>mdi-credit-card-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-credit-card-plus" />
              <code class="mr-2">f675</code>
              <span>mdi-credit-card-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-credit-card-scan" />
              <code class="mr-2">f19d</code>
              <span>mdi-credit-card-scan</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crop" />
              <code class="mr-2">f19e</code>
              <span>mdi-crop</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crop-free" />
              <code class="mr-2">f19f</code>
              <span>mdi-crop-free</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crop-landscape" />
              <code class="mr-2">f1a0</code>
              <span>mdi-crop-landscape</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crop-portrait" />
              <code class="mr-2">f1a1</code>
              <span>mdi-crop-portrait</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crop-rotate" />
              <code class="mr-2">f695</code>
              <span>mdi-crop-rotate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crop-square" />
              <code class="mr-2">f1a2</code>
              <span>mdi-crop-square</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crosshairs" />
              <code class="mr-2">f1a3</code>
              <span>mdi-crosshairs</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crosshairs-gps" />
              <code class="mr-2">f1a4</code>
              <span>mdi-crosshairs-gps</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-crown" />
              <code class="mr-2">f1a5</code>
              <span>mdi-crown</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cube" />
              <code class="mr-2">f1a6</code>
              <span>mdi-cube</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cube-outline" />
              <code class="mr-2">f1a7</code>
              <span>mdi-cube-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cube-send" />
              <code class="mr-2">f1a8</code>
              <span>mdi-cube-send</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cube-unfolded" />
              <code class="mr-2">f1a9</code>
              <span>mdi-cube-unfolded</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cup" />
              <code class="mr-2">f1aa</code>
              <span>mdi-cup</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cup-off" />
              <code class="mr-2">f5e5</code>
              <span>mdi-cup-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cup-water" />
              <code class="mr-2">f1ab</code>
              <span>mdi-cup-water</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-btc" />
              <code class="mr-2">f1ac</code>
              <span>mdi-currency-btc</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-eur" />
              <code class="mr-2">f1ad</code>
              <span>mdi-currency-eur</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-gbp" />
              <code class="mr-2">f1ae</code>
              <span>mdi-currency-gbp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-inr" />
              <code class="mr-2">f1af</code>
              <span>mdi-currency-inr</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-ngn" />
              <code class="mr-2">f1b0</code>
              <span>mdi-currency-ngn</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-rub" />
              <code class="mr-2">f1b1</code>
              <span>mdi-currency-rub</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-try" />
              <code class="mr-2">f1b2</code>
              <span>mdi-currency-try</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-usd" />
              <code class="mr-2">f1b3</code>
              <span>mdi-currency-usd</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-currency-usd-off" />
              <code class="mr-2">f679</code>
              <span>mdi-currency-usd-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cursor-default" />
              <code class="mr-2">f1b4</code>
              <span>mdi-cursor-default</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cursor-default-outline" />
              <code class="mr-2">f1b5</code>
              <span>mdi-cursor-default-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cursor-move" />
              <code class="mr-2">f1b6</code>
              <span>mdi-cursor-move</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cursor-pointer" />
              <code class="mr-2">f1b7</code>
              <span>mdi-cursor-pointer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-cursor-text" />
              <code class="mr-2">f5e7</code>
              <span>mdi-cursor-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-database" />
              <code class="mr-2">f1b8</code>
              <span>mdi-database</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-database-minus" />
              <code class="mr-2">f1b9</code>
              <span>mdi-database-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-database-plus" />
              <code class="mr-2">f1ba</code>
              <span>mdi-database-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-debug-step-into" />
              <code class="mr-2">f1bb</code>
              <span>mdi-debug-step-into</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-debug-step-out" />
              <code class="mr-2">f1bc</code>
              <span>mdi-debug-step-out</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-debug-step-over" />
              <code class="mr-2">f1bd</code>
              <span>mdi-debug-step-over</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-decimal-decrease" />
              <code class="mr-2">f1be</code>
              <span>mdi-decimal-decrease</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-decimal-increase" />
              <code class="mr-2">f1bf</code>
              <span>mdi-decimal-increase</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delete" />
              <code class="mr-2">f1c0</code>
              <span>mdi-delete</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delete-circle" />
              <code class="mr-2">f682</code>
              <span>mdi-delete-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delete-empty" />
              <code class="mr-2">f6cb</code>
              <span>mdi-delete-empty</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delete-forever" />
              <code class="mr-2">f5e8</code>
              <span>mdi-delete-forever</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delete-sweep" />
              <code class="mr-2">f5e9</code>
              <span>mdi-delete-sweep</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delete-variant" />
              <code class="mr-2">f1c1</code>
              <span>mdi-delete-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-delta" />
              <code class="mr-2">f1c2</code>
              <span>mdi-delta</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-deskphone" />
              <code class="mr-2">f1c3</code>
              <span>mdi-deskphone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-desktop-mac" />
              <code class="mr-2">f1c4</code>
              <span>mdi-desktop-mac</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-desktop-tower" />
              <code class="mr-2">f1c5</code>
              <span>mdi-desktop-tower</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-details" />
              <code class="mr-2">f1c6</code>
              <span>mdi-details</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-developer-board" />
              <code class="mr-2">f696</code>
              <span>mdi-developer-board</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-deviantart" />
              <code class="mr-2">f1c7</code>
              <span>mdi-deviantart</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dialpad" />
              <code class="mr-2">f61c</code>
              <span>mdi-dialpad</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-diamond" />
              <code class="mr-2">f1c8</code>
              <span>mdi-diamond</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-1" />
              <code class="mr-2">f1ca</code>
              <span>mdi-dice-1</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-2" />
              <code class="mr-2">f1cb</code>
              <span>mdi-dice-2</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-3" />
              <code class="mr-2">f1cc</code>
              <span>mdi-dice-3</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-4" />
              <code class="mr-2">f1cd</code>
              <span>mdi-dice-4</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-5" />
              <code class="mr-2">f1ce</code>
              <span>mdi-dice-5</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-6" />
              <code class="mr-2">f1cf</code>
              <span>mdi-dice-6</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-d20" />
              <code class="mr-2">f5ea</code>
              <span>mdi-dice-d20</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-d4" />
              <code class="mr-2">f5eb</code>
              <span>mdi-dice-d4</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-d6" />
              <code class="mr-2">f5ec</code>
              <span>mdi-dice-d6</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dice-d8" />
              <code class="mr-2">f5ed</code>
              <span>mdi-dice-d8</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dictionary" />
              <code class="mr-2">f61d</code>
              <span>mdi-dictionary</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-directions" />
              <code class="mr-2">f1d0</code>
              <span>mdi-directions</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-directions-fork" />
              <code class="mr-2">f641</code>
              <span>mdi-directions-fork</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-discord" />
              <code class="mr-2">f66f</code>
              <span>mdi-discord</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-disk" />
              <code class="mr-2">f5ee</code>
              <span>mdi-disk</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-disk-alert" />
              <code class="mr-2">f1d1</code>
              <span>mdi-disk-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-disqus" />
              <code class="mr-2">f1d2</code>
              <span>mdi-disqus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-disqus-outline" />
              <code class="mr-2">f1d3</code>
              <span>mdi-disqus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-division" />
              <code class="mr-2">f1d4</code>
              <span>mdi-division</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-division-box" />
              <code class="mr-2">f1d5</code>
              <span>mdi-division-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dna" />
              <code class="mr-2">f683</code>
              <span>mdi-dna</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dns" />
              <code class="mr-2">f1d6</code>
              <span>mdi-dns</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-do-not-disturb" />
              <code class="mr-2">f697</code>
              <span>mdi-do-not-disturb</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-do-not-disturb-off" />
              <code class="mr-2">f698</code>
              <span>mdi-do-not-disturb-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dolby" />
              <code class="mr-2">f6b2</code>
              <span>mdi-dolby</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-domain" />
              <code class="mr-2">f1d7</code>
              <span>mdi-domain</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dots-horizontal" />
              <code class="mr-2">f1d8</code>
              <span>mdi-dots-horizontal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dots-vertical" />
              <code class="mr-2">f1d9</code>
              <span>mdi-dots-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-douban" />
              <code class="mr-2">f699</code>
              <span>mdi-douban</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-download" />
              <code class="mr-2">f1da</code>
              <span>mdi-download</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drag" />
              <code class="mr-2">f1db</code>
              <span>mdi-drag</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drag-horizontal" />
              <code class="mr-2">f1dc</code>
              <span>mdi-drag-horizontal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drag-vertical" />
              <code class="mr-2">f1dd</code>
              <span>mdi-drag-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drawing" />
              <code class="mr-2">f1de</code>
              <span>mdi-drawing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drawing-box" />
              <code class="mr-2">f1df</code>
              <span>mdi-drawing-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dribbble" />
              <code class="mr-2">f1e0</code>
              <span>mdi-dribbble</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dribbble-box" />
              <code class="mr-2">f1e1</code>
              <span>mdi-dribbble-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drone" />
              <code class="mr-2">f1e2</code>
              <span>mdi-drone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dropbox" />
              <code class="mr-2">f1e3</code>
              <span>mdi-dropbox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-drupal" />
              <code class="mr-2">f1e4</code>
              <span>mdi-drupal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-duck" />
              <code class="mr-2">f1e5</code>
              <span>mdi-duck</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-dumbbell" />
              <code class="mr-2">f1e6</code>
              <span>mdi-dumbbell</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-earth" />
              <code class="mr-2">f1e7</code>
              <span>mdi-earth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-earth-box" />
              <code class="mr-2">f6cc</code>
              <span>mdi-earth-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-earth-box-off" />
              <code class="mr-2">f6cd</code>
              <span>mdi-earth-box-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-earth-off" />
              <code class="mr-2">f1e8</code>
              <span>mdi-earth-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-edge" />
              <code class="mr-2">f1e9</code>
              <span>mdi-edge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eject" />
              <code class="mr-2">f1ea</code>
              <span>mdi-eject</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-elevation-decline" />
              <code class="mr-2">f1eb</code>
              <span>mdi-elevation-decline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-elevation-rise" />
              <code class="mr-2">f1ec</code>
              <span>mdi-elevation-rise</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-elevator" />
              <code class="mr-2">f1ed</code>
              <span>mdi-elevator</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email" />
              <code class="mr-2">f1ee</code>
              <span>mdi-email</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email-alert" />
              <code class="mr-2">f6ce</code>
              <span>mdi-email-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email-open" />
              <code class="mr-2">f1ef</code>
              <span>mdi-email-open</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email-open-outline" />
              <code class="mr-2">f5ef</code>
              <span>mdi-email-open-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email-outline" />
              <code class="mr-2">f1f0</code>
              <span>mdi-email-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email-secure" />
              <code class="mr-2">f1f1</code>
              <span>mdi-email-secure</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-email-variant" />
              <code class="mr-2">f5f0</code>
              <span>mdi-email-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emby" />
              <code class="mr-2">f6b3</code>
              <span>mdi-emby</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon" />
              <code class="mr-2">f1f2</code>
              <span>mdi-emoticon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-cool" />
              <code class="mr-2">f1f3</code>
              <span>mdi-emoticon-cool</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-dead" />
              <code class="mr-2">f69a</code>
              <span>mdi-emoticon-dead</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-devil" />
              <code class="mr-2">f1f4</code>
              <span>mdi-emoticon-devil</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-excited" />
              <code class="mr-2">f69b</code>
              <span>mdi-emoticon-excited</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-happy" />
              <code class="mr-2">f1f5</code>
              <span>mdi-emoticon-happy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-neutral" />
              <code class="mr-2">f1f6</code>
              <span>mdi-emoticon-neutral</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-poop" />
              <code class="mr-2">f1f7</code>
              <span>mdi-emoticon-poop</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-sad" />
              <code class="mr-2">f1f8</code>
              <span>mdi-emoticon-sad</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-emoticon-tongue" />
              <code class="mr-2">f1f9</code>
              <span>mdi-emoticon-tongue</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-engine" />
              <code class="mr-2">f1fa</code>
              <span>mdi-engine</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-engine-outline" />
              <code class="mr-2">f1fb</code>
              <span>mdi-engine-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-equal" />
              <code class="mr-2">f1fc</code>
              <span>mdi-equal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-equal-box" />
              <code class="mr-2">f1fd</code>
              <span>mdi-equal-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eraser" />
              <code class="mr-2">f1fe</code>
              <span>mdi-eraser</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eraser-variant" />
              <code class="mr-2">f642</code>
              <span>mdi-eraser-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-escalator" />
              <code class="mr-2">f1ff</code>
              <span>mdi-escalator</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ethernet" />
              <code class="mr-2">f200</code>
              <span>mdi-ethernet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ethernet-cable" />
              <code class="mr-2">f201</code>
              <span>mdi-ethernet-cable</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ethernet-cable-off" />
              <code class="mr-2">f202</code>
              <span>mdi-ethernet-cable-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-etsy" />
              <code class="mr-2">f203</code>
              <span>mdi-etsy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ev-station" />
              <code class="mr-2">f5f1</code>
              <span>mdi-ev-station</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-evernote" />
              <code class="mr-2">f204</code>
              <span>mdi-evernote</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-exclamation" />
              <code class="mr-2">f205</code>
              <span>mdi-exclamation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-exit-to-app" />
              <code class="mr-2">f206</code>
              <span>mdi-exit-to-app</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-export" />
              <code class="mr-2">f207</code>
              <span>mdi-export</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eye" />
              <code class="mr-2">f208</code>
              <span>mdi-eye</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eye-off" />
              <code class="mr-2">f209</code>
              <span>mdi-eye-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eye-outline" />
              <code class="mr-2">f6cf</code>
              <span>mdi-eye-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eye-outline-off" />
              <code class="mr-2">f6d0</code>
              <span>mdi-eye-outline-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eyedropper" />
              <code class="mr-2">f20a</code>
              <span>mdi-eyedropper</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-eyedropper-variant" />
              <code class="mr-2">f20b</code>
              <span>mdi-eyedropper-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-face" />
              <code class="mr-2">f643</code>
              <span>mdi-face</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-face-profile" />
              <code class="mr-2">f644</code>
              <span>mdi-face-profile</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-facebook" />
              <code class="mr-2">f20c</code>
              <span>mdi-facebook</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-facebook-box" />
              <code class="mr-2">f20d</code>
              <span>mdi-facebook-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-facebook-messenger" />
              <code class="mr-2">f20e</code>
              <span>mdi-facebook-messenger</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-factory" />
              <code class="mr-2">f20f</code>
              <span>mdi-factory</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fan" />
              <code class="mr-2">f210</code>
              <span>mdi-fan</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fast-forward" />
              <code class="mr-2">f211</code>
              <span>mdi-fast-forward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fast-forward-outline" />
              <code class="mr-2">f6d1</code>
              <span>mdi-fast-forward-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fax" />
              <code class="mr-2">f212</code>
              <span>mdi-fax</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-feather" />
              <code class="mr-2">f6d2</code>
              <span>mdi-feather</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ferry" />
              <code class="mr-2">f213</code>
              <span>mdi-ferry</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file" />
              <code class="mr-2">f214</code>
              <span>mdi-file</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-chart" />
              <code class="mr-2">f215</code>
              <span>mdi-file-chart</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-check" />
              <code class="mr-2">f216</code>
              <span>mdi-file-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-cloud" />
              <code class="mr-2">f217</code>
              <span>mdi-file-cloud</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-delimited" />
              <code class="mr-2">f218</code>
              <span>mdi-file-delimited</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-document" />
              <code class="mr-2">f219</code>
              <span>mdi-file-document</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-document-box" />
              <code class="mr-2">f21a</code>
              <span>mdi-file-document-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-excel" />
              <code class="mr-2">f21b</code>
              <span>mdi-file-excel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-excel-box" />
              <code class="mr-2">f21c</code>
              <span>mdi-file-excel-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-export" />
              <code class="mr-2">f21d</code>
              <span>mdi-file-export</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-find" />
              <code class="mr-2">f21e</code>
              <span>mdi-file-find</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-hidden" />
              <code class="mr-2">f613</code>
              <span>mdi-file-hidden</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-image" />
              <code class="mr-2">f21f</code>
              <span>mdi-file-image</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-import" />
              <code class="mr-2">f220</code>
              <span>mdi-file-import</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-lock" />
              <code class="mr-2">f221</code>
              <span>mdi-file-lock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-multiple" />
              <code class="mr-2">f222</code>
              <span>mdi-file-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-music" />
              <code class="mr-2">f223</code>
              <span>mdi-file-music</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-outline" />
              <code class="mr-2">f224</code>
              <span>mdi-file-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-pdf" />
              <code class="mr-2">f225</code>
              <span>mdi-file-pdf</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-pdf-box" />
              <code class="mr-2">f226</code>
              <span>mdi-file-pdf-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-powerpoint" />
              <code class="mr-2">f227</code>
              <span>mdi-file-powerpoint</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-powerpoint-box" />
              <code class="mr-2">f228</code>
              <span>mdi-file-powerpoint-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-presentation-box" />
              <code class="mr-2">f229</code>
              <span>mdi-file-presentation-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-restore" />
              <code class="mr-2">f670</code>
              <span>mdi-file-restore</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-send" />
              <code class="mr-2">f22a</code>
              <span>mdi-file-send</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-tree" />
              <code class="mr-2">f645</code>
              <span>mdi-file-tree</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-video" />
              <code class="mr-2">f22b</code>
              <span>mdi-file-video</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-word" />
              <code class="mr-2">f22c</code>
              <span>mdi-file-word</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-word-box" />
              <code class="mr-2">f22d</code>
              <span>mdi-file-word-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-file-xml" />
              <code class="mr-2">f22e</code>
              <span>mdi-file-xml</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-film" />
              <code class="mr-2">f22f</code>
              <span>mdi-film</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filmstrip" />
              <code class="mr-2">f230</code>
              <span>mdi-filmstrip</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filmstrip-off" />
              <code class="mr-2">f231</code>
              <span>mdi-filmstrip-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filter" />
              <code class="mr-2">f232</code>
              <span>mdi-filter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filter-outline" />
              <code class="mr-2">f233</code>
              <span>mdi-filter-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filter-remove" />
              <code class="mr-2">f234</code>
              <span>mdi-filter-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filter-remove-outline" />
              <code class="mr-2">f235</code>
              <span>mdi-filter-remove-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-filter-variant" />
              <code class="mr-2">f236</code>
              <span>mdi-filter-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-find-replace" />
              <code class="mr-2">f6d3</code>
              <span>mdi-find-replace</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fingerprint" />
              <code class="mr-2">f237</code>
              <span>mdi-fingerprint</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fire" />
              <code class="mr-2">f238</code>
              <span>mdi-fire</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-firefox" />
              <code class="mr-2">f239</code>
              <span>mdi-firefox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fish" />
              <code class="mr-2">f23a</code>
              <span>mdi-fish</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flag" />
              <code class="mr-2">f23b</code>
              <span>mdi-flag</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flag-checkered" />
              <code class="mr-2">f23c</code>
              <span>mdi-flag-checkered</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flag-outline" />
              <code class="mr-2">f23d</code>
              <span>mdi-flag-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flag-outline-variant" />
              <code class="mr-2">f23e</code>
              <span>mdi-flag-outline-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flag-triangle" />
              <code class="mr-2">f23f</code>
              <span>mdi-flag-triangle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flag-variant" />
              <code class="mr-2">f240</code>
              <span>mdi-flag-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flash" />
              <code class="mr-2">f241</code>
              <span>mdi-flash</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flash-auto" />
              <code class="mr-2">f242</code>
              <span>mdi-flash-auto</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flash-off" />
              <code class="mr-2">f243</code>
              <span>mdi-flash-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flash-outline" />
              <code class="mr-2">f6d4</code>
              <span>mdi-flash-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flash-red-eye" />
              <code class="mr-2">f67a</code>
              <span>mdi-flash-red-eye</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flashlight" />
              <code class="mr-2">f244</code>
              <span>mdi-flashlight</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flashlight-off" />
              <code class="mr-2">f245</code>
              <span>mdi-flashlight-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flask" />
              <code class="mr-2">f093</code>
              <span>mdi-flask</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flask-empty" />
              <code class="mr-2">f094</code>
              <span>mdi-flask-empty</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flask-empty-outline" />
              <code class="mr-2">f095</code>
              <span>mdi-flask-empty-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flask-outline" />
              <code class="mr-2">f096</code>
              <span>mdi-flask-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flattr" />
              <code class="mr-2">f246</code>
              <span>mdi-flattr</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flip-to-back" />
              <code class="mr-2">f247</code>
              <span>mdi-flip-to-back</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flip-to-front" />
              <code class="mr-2">f248</code>
              <span>mdi-flip-to-front</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-floppy" />
              <code class="mr-2">f249</code>
              <span>mdi-floppy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-flower" />
              <code class="mr-2">f24a</code>
              <span>mdi-flower</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder" />
              <code class="mr-2">f24b</code>
              <span>mdi-folder</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-account" />
              <code class="mr-2">f24c</code>
              <span>mdi-folder-account</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-download" />
              <code class="mr-2">f24d</code>
              <span>mdi-folder-download</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-google-drive" />
              <code class="mr-2">f24e</code>
              <span>mdi-folder-google-drive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-image" />
              <code class="mr-2">f24f</code>
              <span>mdi-folder-image</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-lock" />
              <code class="mr-2">f250</code>
              <span>mdi-folder-lock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-lock-open" />
              <code class="mr-2">f251</code>
              <span>mdi-folder-lock-open</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-move" />
              <code class="mr-2">f252</code>
              <span>mdi-folder-move</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-multiple" />
              <code class="mr-2">f253</code>
              <span>mdi-folder-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-multiple-image" />
              <code class="mr-2">f254</code>
              <span>mdi-folder-multiple-image</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-multiple-outline" />
              <code class="mr-2">f255</code>
              <span>mdi-folder-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-outline" />
              <code class="mr-2">f256</code>
              <span>mdi-folder-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-plus" />
              <code class="mr-2">f257</code>
              <span>mdi-folder-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-remove" />
              <code class="mr-2">f258</code>
              <span>mdi-folder-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-star" />
              <code class="mr-2">f69c</code>
              <span>mdi-folder-star</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-folder-upload" />
              <code class="mr-2">f259</code>
              <span>mdi-folder-upload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-font-awesome" />
              <code class="mr-2">f03a</code>
              <span>mdi-font-awesome</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-food" />
              <code class="mr-2">f25a</code>
              <span>mdi-food</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-food-apple" />
              <code class="mr-2">f25b</code>
              <span>mdi-food-apple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-food-fork-drink" />
              <code class="mr-2">f5f2</code>
              <span>mdi-food-fork-drink</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-food-off" />
              <code class="mr-2">f5f3</code>
              <span>mdi-food-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-food-variant" />
              <code class="mr-2">f25c</code>
              <span>mdi-food-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-football" />
              <code class="mr-2">f25d</code>
              <span>mdi-football</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-football-australian" />
              <code class="mr-2">f25e</code>
              <span>mdi-football-australian</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-football-helmet" />
              <code class="mr-2">f25f</code>
              <span>mdi-football-helmet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-align-center" />
              <code class="mr-2">f260</code>
              <span>mdi-format-align-center</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-align-justify" />
              <code class="mr-2">f261</code>
              <span>mdi-format-align-justify</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-align-left" />
              <code class="mr-2">f262</code>
              <span>mdi-format-align-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-align-right" />
              <code class="mr-2">f263</code>
              <span>mdi-format-align-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-annotation-plus" />
              <code class="mr-2">f646</code>
              <span>mdi-format-annotation-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-bold" />
              <code class="mr-2">f264</code>
              <span>mdi-format-bold</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-clear" />
              <code class="mr-2">f265</code>
              <span>mdi-format-clear</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-color-fill" />
              <code class="mr-2">f266</code>
              <span>mdi-format-color-fill</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-color-text" />
              <code class="mr-2">f69d</code>
              <span>mdi-format-color-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-float-center" />
              <code class="mr-2">f267</code>
              <span>mdi-format-float-center</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-float-left" />
              <code class="mr-2">f268</code>
              <span>mdi-format-float-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-float-none" />
              <code class="mr-2">f269</code>
              <span>mdi-format-float-none</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-float-right" />
              <code class="mr-2">f26a</code>
              <span>mdi-format-float-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-font" />
              <code class="mr-2">f6d5</code>
              <span>mdi-format-font</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-1" />
              <code class="mr-2">f26b</code>
              <span>mdi-format-header-1</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-2" />
              <code class="mr-2">f26c</code>
              <span>mdi-format-header-2</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-3" />
              <code class="mr-2">f26d</code>
              <span>mdi-format-header-3</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-4" />
              <code class="mr-2">f26e</code>
              <span>mdi-format-header-4</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-5" />
              <code class="mr-2">f26f</code>
              <span>mdi-format-header-5</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-6" />
              <code class="mr-2">f270</code>
              <span>mdi-format-header-6</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-decrease" />
              <code class="mr-2">f271</code>
              <span>mdi-format-header-decrease</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-equal" />
              <code class="mr-2">f272</code>
              <span>mdi-format-header-equal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-increase" />
              <code class="mr-2">f273</code>
              <span>mdi-format-header-increase</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-header-pound" />
              <code class="mr-2">f274</code>
              <span>mdi-format-header-pound</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-horizontal-align-center" />
              <code class="mr-2">f61e</code>
              <span>mdi-format-horizontal-align-center</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-horizontal-align-left" />
              <code class="mr-2">f61f</code>
              <span>mdi-format-horizontal-align-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-horizontal-align-right" />
              <code class="mr-2">f620</code>
              <span>mdi-format-horizontal-align-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-indent-decrease" />
              <code class="mr-2">f275</code>
              <span>mdi-format-indent-decrease</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-indent-increase" />
              <code class="mr-2">f276</code>
              <span>mdi-format-indent-increase</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-italic" />
              <code class="mr-2">f277</code>
              <span>mdi-format-italic</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-line-spacing" />
              <code class="mr-2">f278</code>
              <span>mdi-format-line-spacing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-line-style" />
              <code class="mr-2">f5c8</code>
              <span>mdi-format-line-style</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-line-weight" />
              <code class="mr-2">f5c9</code>
              <span>mdi-format-line-weight</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-list-bulleted" />
              <code class="mr-2">f279</code>
              <span>mdi-format-list-bulleted</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-list-bulleted-type" />
              <code class="mr-2">f27a</code>
              <span>mdi-format-list-bulleted-type</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-list-numbers" />
              <code class="mr-2">f27b</code>
              <span>mdi-format-list-numbers</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-page-break" />
              <code class="mr-2">f6d6</code>
              <span>mdi-format-page-break</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-paint" />
              <code class="mr-2">f27c</code>
              <span>mdi-format-paint</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-paragraph" />
              <code class="mr-2">f27d</code>
              <span>mdi-format-paragraph</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-pilcrow" />
              <code class="mr-2">f6d7</code>
              <span>mdi-format-pilcrow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-quote" />
              <code class="mr-2">f27e</code>
              <span>mdi-format-quote</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-rotate-90" />
              <code class="mr-2">f6a9</code>
              <span>mdi-format-rotate-90</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-section" />
              <code class="mr-2">f69e</code>
              <span>mdi-format-section</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-size" />
              <code class="mr-2">f27f</code>
              <span>mdi-format-size</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-strikethrough" />
              <code class="mr-2">f280</code>
              <span>mdi-format-strikethrough</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-strikethrough-variant" />
              <code class="mr-2">f281</code>
              <span>mdi-format-strikethrough-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-subscript" />
              <code class="mr-2">f282</code>
              <span>mdi-format-subscript</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-superscript" />
              <code class="mr-2">f283</code>
              <span>mdi-format-superscript</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-text" />
              <code class="mr-2">f284</code>
              <span>mdi-format-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-textdirection-l-to-r" />
              <code class="mr-2">f285</code>
              <span>mdi-format-textdirection-l-to-r</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-textdirection-r-to-l" />
              <code class="mr-2">f286</code>
              <span>mdi-format-textdirection-r-to-l</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-title" />
              <code class="mr-2">f5f4</code>
              <span>mdi-format-title</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-underline" />
              <code class="mr-2">f287</code>
              <span>mdi-format-underline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-vertical-align-bottom" />
              <code class="mr-2">f621</code>
              <span>mdi-format-vertical-align-bottom</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-vertical-align-center" />
              <code class="mr-2">f622</code>
              <span>mdi-format-vertical-align-center</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-vertical-align-top" />
              <code class="mr-2">f623</code>
              <span>mdi-format-vertical-align-top</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-wrap-inline" />
              <code class="mr-2">f288</code>
              <span>mdi-format-wrap-inline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-wrap-square" />
              <code class="mr-2">f289</code>
              <span>mdi-format-wrap-square</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-wrap-tight" />
              <code class="mr-2">f28a</code>
              <span>mdi-format-wrap-tight</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-format-wrap-top-bottom" />
              <code class="mr-2">f28b</code>
              <span>mdi-format-wrap-top-bottom</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-forum" />
              <code class="mr-2">f28c</code>
              <span>mdi-forum</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-forward" />
              <code class="mr-2">f28d</code>
              <span>mdi-forward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-foursquare" />
              <code class="mr-2">f28e</code>
              <span>mdi-foursquare</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fridge" />
              <code class="mr-2">f28f</code>
              <span>mdi-fridge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fridge-filled" />
              <code class="mr-2">f290</code>
              <span>mdi-fridge-filled</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fridge-filled-bottom" />
              <code class="mr-2">f291</code>
              <span>mdi-fridge-filled-bottom</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fridge-filled-top" />
              <code class="mr-2">f292</code>
              <span>mdi-fridge-filled-top</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fullscreen" />
              <code class="mr-2">f293</code>
              <span>mdi-fullscreen</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-fullscreen-exit" />
              <code class="mr-2">f294</code>
              <span>mdi-fullscreen-exit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-function" />
              <code class="mr-2">f295</code>
              <span>mdi-function</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gamepad" />
              <code class="mr-2">f296</code>
              <span>mdi-gamepad</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gamepad-variant" />
              <code class="mr-2">f297</code>
              <span>mdi-gamepad-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-garage" />
              <code class="mr-2">f6d8</code>
              <span>mdi-garage</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-garage-open" />
              <code class="mr-2">f6d9</code>
              <span>mdi-garage-open</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gas-cylinder" />
              <code class="mr-2">f647</code>
              <span>mdi-gas-cylinder</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gas-station" />
              <code class="mr-2">f298</code>
              <span>mdi-gas-station</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gate" />
              <code class="mr-2">f299</code>
              <span>mdi-gate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gauge" />
              <code class="mr-2">f29a</code>
              <span>mdi-gauge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gavel" />
              <code class="mr-2">f29b</code>
              <span>mdi-gavel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gender-female" />
              <code class="mr-2">f29c</code>
              <span>mdi-gender-female</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gender-male" />
              <code class="mr-2">f29d</code>
              <span>mdi-gender-male</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gender-male-female" />
              <code class="mr-2">f29e</code>
              <span>mdi-gender-male-female</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gender-transgender" />
              <code class="mr-2">f29f</code>
              <span>mdi-gender-transgender</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ghost" />
              <code class="mr-2">f2a0</code>
              <span>mdi-ghost</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gift" />
              <code class="mr-2">f2a1</code>
              <span>mdi-gift</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-git" />
              <code class="mr-2">f2a2</code>
              <span>mdi-git</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-github-box" />
              <code class="mr-2">f2a3</code>
              <span>mdi-github-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-github-circle" />
              <code class="mr-2">f2a4</code>
              <span>mdi-github-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-github-face" />
              <code class="mr-2">f6da</code>
              <span>mdi-github-face</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-glass-flute" />
              <code class="mr-2">f2a5</code>
              <span>mdi-glass-flute</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-glass-mug" />
              <code class="mr-2">f2a6</code>
              <span>mdi-glass-mug</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-glass-stange" />
              <code class="mr-2">f2a7</code>
              <span>mdi-glass-stange</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-glass-tulip" />
              <code class="mr-2">f2a8</code>
              <span>mdi-glass-tulip</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-glassdoor" />
              <code class="mr-2">f2a9</code>
              <span>mdi-glassdoor</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-glasses" />
              <code class="mr-2">f2aa</code>
              <span>mdi-glasses</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gmail" />
              <code class="mr-2">f2ab</code>
              <span>mdi-gmail</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gnome" />
              <code class="mr-2">f2ac</code>
              <span>mdi-gnome</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gondola" />
              <code class="mr-2">f685</code>
              <span>mdi-gondola</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google" />
              <code class="mr-2">f2ad</code>
              <span>mdi-google</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-cardboard" />
              <code class="mr-2">f2ae</code>
              <span>mdi-google-cardboard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-chrome" />
              <code class="mr-2">f2af</code>
              <span>mdi-google-chrome</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-circles" />
              <code class="mr-2">f2b0</code>
              <span>mdi-google-circles</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-circles-communities" />
              <code class="mr-2">f2b1</code>
              <span>mdi-google-circles-communities</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-circles-extended" />
              <code class="mr-2">f2b2</code>
              <span>mdi-google-circles-extended</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-circles-group" />
              <code class="mr-2">f2b3</code>
              <span>mdi-google-circles-group</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-controller" />
              <code class="mr-2">f2b4</code>
              <span>mdi-google-controller</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-controller-off" />
              <code class="mr-2">f2b5</code>
              <span>mdi-google-controller-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-drive" />
              <code class="mr-2">f2b6</code>
              <span>mdi-google-drive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-earth" />
              <code class="mr-2">f2b7</code>
              <span>mdi-google-earth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-glass" />
              <code class="mr-2">f2b8</code>
              <span>mdi-google-glass</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-keep" />
              <code class="mr-2">f6db</code>
              <span>mdi-google-keep</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-maps" />
              <code class="mr-2">f5f5</code>
              <span>mdi-google-maps</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-nearby" />
              <code class="mr-2">f2b9</code>
              <span>mdi-google-nearby</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-pages" />
              <code class="mr-2">f2ba</code>
              <span>mdi-google-pages</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-photos" />
              <code class="mr-2">f6dc</code>
              <span>mdi-google-photos</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-physical-web" />
              <code class="mr-2">f2bb</code>
              <span>mdi-google-physical-web</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-play" />
              <code class="mr-2">f2bc</code>
              <span>mdi-google-play</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-plus" />
              <code class="mr-2">f2bd</code>
              <span>mdi-google-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-plus-box" />
              <code class="mr-2">f2be</code>
              <span>mdi-google-plus-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-translate" />
              <code class="mr-2">f2bf</code>
              <span>mdi-google-translate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-google-wallet" />
              <code class="mr-2">f2c0</code>
              <span>mdi-google-wallet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-gradient" />
              <code class="mr-2">f69f</code>
              <span>mdi-gradient</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-grease-pencil" />
              <code class="mr-2">f648</code>
              <span>mdi-grease-pencil</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-grid" />
              <code class="mr-2">f2c1</code>
              <span>mdi-grid</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-grid-off" />
              <code class="mr-2">f2c2</code>
              <span>mdi-grid-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-group" />
              <code class="mr-2">f2c3</code>
              <span>mdi-group</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-guitar-electric" />
              <code class="mr-2">f2c4</code>
              <span>mdi-guitar-electric</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-guitar-pick" />
              <code class="mr-2">f2c5</code>
              <span>mdi-guitar-pick</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-guitar-pick-outline" />
              <code class="mr-2">f2c6</code>
              <span>mdi-guitar-pick-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hackernews" />
              <code class="mr-2">f624</code>
              <span>mdi-hackernews</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hamburger" />
              <code class="mr-2">f684</code>
              <span>mdi-hamburger</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hand-pointing-right" />
              <code class="mr-2">f2c7</code>
              <span>mdi-hand-pointing-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hanger" />
              <code class="mr-2">f2c8</code>
              <span>mdi-hanger</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hangouts" />
              <code class="mr-2">f2c9</code>
              <span>mdi-hangouts</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-harddisk" />
              <code class="mr-2">f2ca</code>
              <span>mdi-harddisk</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-headphones" />
              <code class="mr-2">f2cb</code>
              <span>mdi-headphones</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-headphones-box" />
              <code class="mr-2">f2cc</code>
              <span>mdi-headphones-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-headphones-settings" />
              <code class="mr-2">f2cd</code>
              <span>mdi-headphones-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-headset" />
              <code class="mr-2">f2ce</code>
              <span>mdi-headset</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-headset-dock" />
              <code class="mr-2">f2cf</code>
              <span>mdi-headset-dock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-headset-off" />
              <code class="mr-2">f2d0</code>
              <span>mdi-headset-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart" />
              <code class="mr-2">f2d1</code>
              <span>mdi-heart</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-box" />
              <code class="mr-2">f2d2</code>
              <span>mdi-heart-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-box-outline" />
              <code class="mr-2">f2d3</code>
              <span>mdi-heart-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-broken" />
              <code class="mr-2">f2d4</code>
              <span>mdi-heart-broken</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-half-outline" />
              <code class="mr-2">f6dd</code>
              <span>mdi-heart-half-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-half-part" />
              <code class="mr-2">f6de</code>
              <span>mdi-heart-half-part</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-half-part-outline" />
              <code class="mr-2">f6df</code>
              <span>mdi-heart-half-part-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-outline" />
              <code class="mr-2">f2d5</code>
              <span>mdi-heart-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-heart-pulse" />
              <code class="mr-2">f5f6</code>
              <span>mdi-heart-pulse</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-help" />
              <code class="mr-2">f2d6</code>
              <span>mdi-help</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-help-circle" />
              <code class="mr-2">f2d7</code>
              <span>mdi-help-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-help-circle-outline" />
              <code class="mr-2">f625</code>
              <span>mdi-help-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hexagon" />
              <code class="mr-2">f2d8</code>
              <span>mdi-hexagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hexagon-multiple" />
              <code class="mr-2">f6e0</code>
              <span>mdi-hexagon-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hexagon-outline" />
              <code class="mr-2">f2d9</code>
              <span>mdi-hexagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-highway" />
              <code class="mr-2">f5f7</code>
              <span>mdi-highway</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-history" />
              <code class="mr-2">f2da</code>
              <span>mdi-history</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hololens" />
              <code class="mr-2">f2db</code>
              <span>mdi-hololens</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-home" />
              <code class="mr-2">f2dc</code>
              <span>mdi-home</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-home-map-marker" />
              <code class="mr-2">f5f8</code>
              <span>mdi-home-map-marker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-home-modern" />
              <code class="mr-2">f2dd</code>
              <span>mdi-home-modern</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-home-outline" />
              <code class="mr-2">f6a0</code>
              <span>mdi-home-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-home-variant" />
              <code class="mr-2">f2de</code>
              <span>mdi-home-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hook" />
              <code class="mr-2">f6e1</code>
              <span>mdi-hook</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hook-off" />
              <code class="mr-2">f6e2</code>
              <span>mdi-hook-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hops" />
              <code class="mr-2">f2df</code>
              <span>mdi-hops</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hospital" />
              <code class="mr-2">f2e0</code>
              <span>mdi-hospital</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hospital-building" />
              <code class="mr-2">f2e1</code>
              <span>mdi-hospital-building</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hospital-marker" />
              <code class="mr-2">f2e2</code>
              <span>mdi-hospital-marker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-hotel" />
              <code class="mr-2">f2e3</code>
              <span>mdi-hotel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-houzz" />
              <code class="mr-2">f2e4</code>
              <span>mdi-houzz</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-houzz-box" />
              <code class="mr-2">f2e5</code>
              <span>mdi-houzz-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human" />
              <code class="mr-2">f2e6</code>
              <span>mdi-human</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-child" />
              <code class="mr-2">f2e7</code>
              <span>mdi-human-child</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-female" />
              <code class="mr-2">f649</code>
              <span>mdi-human-female</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-greeting" />
              <code class="mr-2">f64a</code>
              <span>mdi-human-greeting</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-handsdown" />
              <code class="mr-2">f64b</code>
              <span>mdi-human-handsdown</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-handsup" />
              <code class="mr-2">f64c</code>
              <span>mdi-human-handsup</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-male" />
              <code class="mr-2">f64d</code>
              <span>mdi-human-male</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-male-female" />
              <code class="mr-2">f2e8</code>
              <span>mdi-human-male-female</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-human-pregnant" />
              <code class="mr-2">f5cf</code>
              <span>mdi-human-pregnant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image" />
              <code class="mr-2">f2e9</code>
              <span>mdi-image</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-album" />
              <code class="mr-2">f2ea</code>
              <span>mdi-image-album</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-area" />
              <code class="mr-2">f2eb</code>
              <span>mdi-image-area</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-area-close" />
              <code class="mr-2">f2ec</code>
              <span>mdi-image-area-close</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-broken" />
              <code class="mr-2">f2ed</code>
              <span>mdi-image-broken</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-broken-variant" />
              <code class="mr-2">f2ee</code>
              <span>mdi-image-broken-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter" />
              <code class="mr-2">f2ef</code>
              <span>mdi-image-filter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-black-white" />
              <code class="mr-2">f2f0</code>
              <span>mdi-image-filter-black-white</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-center-focus" />
              <code class="mr-2">f2f1</code>
              <span>mdi-image-filter-center-focus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-center-focus-weak" />
              <code class="mr-2">f2f2</code>
              <span>mdi-image-filter-center-focus-weak</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-drama" />
              <code class="mr-2">f2f3</code>
              <span>mdi-image-filter-drama</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-frames" />
              <code class="mr-2">f2f4</code>
              <span>mdi-image-filter-frames</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-hdr" />
              <code class="mr-2">f2f5</code>
              <span>mdi-image-filter-hdr</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-none" />
              <code class="mr-2">f2f6</code>
              <span>mdi-image-filter-none</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-tilt-shift" />
              <code class="mr-2">f2f7</code>
              <span>mdi-image-filter-tilt-shift</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-filter-vintage" />
              <code class="mr-2">f2f8</code>
              <span>mdi-image-filter-vintage</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-image-multiple" />
              <code class="mr-2">f2f9</code>
              <span>mdi-image-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-import" />
              <code class="mr-2">f2fa</code>
              <span>mdi-import</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-inbox" />
              <code class="mr-2">f686</code>
              <span>mdi-inbox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-inbox-arrow-down" />
              <code class="mr-2">f2fb</code>
              <span>mdi-inbox-arrow-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-inbox-arrow-up" />
              <code class="mr-2">f3d1</code>
              <span>mdi-inbox-arrow-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-incognito" />
              <code class="mr-2">f5f9</code>
              <span>mdi-incognito</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-infinity" />
              <code class="mr-2">f6e3</code>
              <span>mdi-infinity</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-information" />
              <code class="mr-2">f2fc</code>
              <span>mdi-information</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-information-outline" />
              <code class="mr-2">f2fd</code>
              <span>mdi-information-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-information-variant" />
              <code class="mr-2">f64e</code>
              <span>mdi-information-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-instagram" />
              <code class="mr-2">f2fe</code>
              <span>mdi-instagram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-instapaper" />
              <code class="mr-2">f2ff</code>
              <span>mdi-instapaper</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-internet-explorer" />
              <code class="mr-2">f300</code>
              <span>mdi-internet-explorer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-invert-colors" />
              <code class="mr-2">f301</code>
              <span>mdi-invert-colors</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-itunes" />
              <code class="mr-2">f676</code>
              <span>mdi-itunes</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-jeepney" />
              <code class="mr-2">f302</code>
              <span>mdi-jeepney</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-jira" />
              <code class="mr-2">f303</code>
              <span>mdi-jira</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-jsfiddle" />
              <code class="mr-2">f304</code>
              <span>mdi-jsfiddle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-json" />
              <code class="mr-2">f626</code>
              <span>mdi-json</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keg" />
              <code class="mr-2">f305</code>
              <span>mdi-keg</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-kettle" />
              <code class="mr-2">f5fa</code>
              <span>mdi-kettle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-key" />
              <code class="mr-2">f306</code>
              <span>mdi-key</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-key-change" />
              <code class="mr-2">f307</code>
              <span>mdi-key-change</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-key-minus" />
              <code class="mr-2">f308</code>
              <span>mdi-key-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-key-plus" />
              <code class="mr-2">f309</code>
              <span>mdi-key-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-key-remove" />
              <code class="mr-2">f30a</code>
              <span>mdi-key-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-key-variant" />
              <code class="mr-2">f30b</code>
              <span>mdi-key-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard" />
              <code class="mr-2">f30c</code>
              <span>mdi-keyboard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-backspace" />
              <code class="mr-2">f30d</code>
              <span>mdi-keyboard-backspace</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-caps" />
              <code class="mr-2">f30e</code>
              <span>mdi-keyboard-caps</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-close" />
              <code class="mr-2">f30f</code>
              <span>mdi-keyboard-close</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-off" />
              <code class="mr-2">f310</code>
              <span>mdi-keyboard-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-return" />
              <code class="mr-2">f311</code>
              <span>mdi-keyboard-return</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-tab" />
              <code class="mr-2">f312</code>
              <span>mdi-keyboard-tab</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-keyboard-variant" />
              <code class="mr-2">f313</code>
              <span>mdi-keyboard-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-kodi" />
              <code class="mr-2">f314</code>
              <span>mdi-kodi</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-label" />
              <code class="mr-2">f315</code>
              <span>mdi-label</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-label-outline" />
              <code class="mr-2">f316</code>
              <span>mdi-label-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lambda" />
              <code class="mr-2">f627</code>
              <span>mdi-lambda</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lamp" />
              <code class="mr-2">f6b4</code>
              <span>mdi-lamp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lan" />
              <code class="mr-2">f317</code>
              <span>mdi-lan</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lan-connect" />
              <code class="mr-2">f318</code>
              <span>mdi-lan-connect</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lan-disconnect" />
              <code class="mr-2">f319</code>
              <span>mdi-lan-disconnect</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lan-pending" />
              <code class="mr-2">f31a</code>
              <span>mdi-lan-pending</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-c" />
              <code class="mr-2">f671</code>
              <span>mdi-language-c</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-cpp" />
              <code class="mr-2">f672</code>
              <span>mdi-language-cpp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-csharp" />
              <code class="mr-2">f31b</code>
              <span>mdi-language-csharp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-css3" />
              <code class="mr-2">f31c</code>
              <span>mdi-language-css3</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-html5" />
              <code class="mr-2">f31d</code>
              <span>mdi-language-html5</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-javascript" />
              <code class="mr-2">f31e</code>
              <span>mdi-language-javascript</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-php" />
              <code class="mr-2">f31f</code>
              <span>mdi-language-php</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-python" />
              <code class="mr-2">f320</code>
              <span>mdi-language-python</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-python-text" />
              <code class="mr-2">f321</code>
              <span>mdi-language-python-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-swift" />
              <code class="mr-2">f6e4</code>
              <span>mdi-language-swift</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-language-typescript" />
              <code class="mr-2">f6e5</code>
              <span>mdi-language-typescript</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-laptop" />
              <code class="mr-2">f322</code>
              <span>mdi-laptop</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-laptop-chromebook" />
              <code class="mr-2">f323</code>
              <span>mdi-laptop-chromebook</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-laptop-mac" />
              <code class="mr-2">f324</code>
              <span>mdi-laptop-mac</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-laptop-off" />
              <code class="mr-2">f6e6</code>
              <span>mdi-laptop-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-laptop-windows" />
              <code class="mr-2">f325</code>
              <span>mdi-laptop-windows</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lastfm" />
              <code class="mr-2">f326</code>
              <span>mdi-lastfm</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-launch" />
              <code class="mr-2">f327</code>
              <span>mdi-launch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-layers" />
              <code class="mr-2">f328</code>
              <span>mdi-layers</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-layers-off" />
              <code class="mr-2">f329</code>
              <span>mdi-layers-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lead-pencil" />
              <code class="mr-2">f64f</code>
              <span>mdi-lead-pencil</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-leaf" />
              <code class="mr-2">f32a</code>
              <span>mdi-leaf</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-led-off" />
              <code class="mr-2">f32b</code>
              <span>mdi-led-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-led-on" />
              <code class="mr-2">f32c</code>
              <span>mdi-led-on</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-led-outline" />
              <code class="mr-2">f32d</code>
              <span>mdi-led-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-led-variant-off" />
              <code class="mr-2">f32e</code>
              <span>mdi-led-variant-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-led-variant-on" />
              <code class="mr-2">f32f</code>
              <span>mdi-led-variant-on</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-led-variant-outline" />
              <code class="mr-2">f330</code>
              <span>mdi-led-variant-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-library" />
              <code class="mr-2">f331</code>
              <span>mdi-library</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-library-books" />
              <code class="mr-2">f332</code>
              <span>mdi-library-books</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-library-music" />
              <code class="mr-2">f333</code>
              <span>mdi-library-music</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-library-plus" />
              <code class="mr-2">f334</code>
              <span>mdi-library-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lightbulb" />
              <code class="mr-2">f335</code>
              <span>mdi-lightbulb</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lightbulb-on" />
              <code class="mr-2">f6e7</code>
              <span>mdi-lightbulb-on</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lightbulb-on-outline" />
              <code class="mr-2">f6e8</code>
              <span>mdi-lightbulb-on-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lightbulb-outline" />
              <code class="mr-2">f336</code>
              <span>mdi-lightbulb-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-link" />
              <code class="mr-2">f337</code>
              <span>mdi-link</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-link-off" />
              <code class="mr-2">f338</code>
              <span>mdi-link-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-link-variant" />
              <code class="mr-2">f339</code>
              <span>mdi-link-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-link-variant-off" />
              <code class="mr-2">f33a</code>
              <span>mdi-link-variant-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-linkedin" />
              <code class="mr-2">f33b</code>
              <span>mdi-linkedin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-linkedin-box" />
              <code class="mr-2">f33c</code>
              <span>mdi-linkedin-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-linux" />
              <code class="mr-2">f33d</code>
              <span>mdi-linux</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lock" />
              <code class="mr-2">f33e</code>
              <span>mdi-lock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lock-open" />
              <code class="mr-2">f33f</code>
              <span>mdi-lock-open</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lock-open-outline" />
              <code class="mr-2">f340</code>
              <span>mdi-lock-open-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lock-outline" />
              <code class="mr-2">f341</code>
              <span>mdi-lock-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lock-pattern" />
              <code class="mr-2">f6e9</code>
              <span>mdi-lock-pattern</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lock-plus" />
              <code class="mr-2">f5fb</code>
              <span>mdi-lock-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-login" />
              <code class="mr-2">f342</code>
              <span>mdi-login</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-login-variant" />
              <code class="mr-2">f5fc</code>
              <span>mdi-login-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-logout" />
              <code class="mr-2">f343</code>
              <span>mdi-logout</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-logout-variant" />
              <code class="mr-2">f5fd</code>
              <span>mdi-logout-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-looks" />
              <code class="mr-2">f344</code>
              <span>mdi-looks</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-loop" />
              <code class="mr-2">f6ea</code>
              <span>mdi-loop</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-loupe" />
              <code class="mr-2">f345</code>
              <span>mdi-loupe</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-lumx" />
              <code class="mr-2">f346</code>
              <span>mdi-lumx</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnet" />
              <code class="mr-2">f347</code>
              <span>mdi-magnet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnet-on" />
              <code class="mr-2">f348</code>
              <span>mdi-magnet-on</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnify" />
              <code class="mr-2">f349</code>
              <span>mdi-magnify</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnify-minus" />
              <code class="mr-2">f34a</code>
              <span>mdi-magnify-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnify-minus-outline" />
              <code class="mr-2">f6eb</code>
              <span>mdi-magnify-minus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnify-plus" />
              <code class="mr-2">f34b</code>
              <span>mdi-magnify-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-magnify-plus-outline" />
              <code class="mr-2">f6ec</code>
              <span>mdi-magnify-plus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mail-ru" />
              <code class="mr-2">f34c</code>
              <span>mdi-mail-ru</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mailbox" />
              <code class="mr-2">f6ed</code>
              <span>mdi-mailbox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map" />
              <code class="mr-2">f34d</code>
              <span>mdi-map</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker" />
              <code class="mr-2">f34e</code>
              <span>mdi-map-marker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker-circle" />
              <code class="mr-2">f34f</code>
              <span>mdi-map-marker-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker-minus" />
              <code class="mr-2">f650</code>
              <span>mdi-map-marker-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker-multiple" />
              <code class="mr-2">f350</code>
              <span>mdi-map-marker-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker-off" />
              <code class="mr-2">f351</code>
              <span>mdi-map-marker-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker-plus" />
              <code class="mr-2">f651</code>
              <span>mdi-map-marker-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-map-marker-radius" />
              <code class="mr-2">f352</code>
              <span>mdi-map-marker-radius</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-margin" />
              <code class="mr-2">f353</code>
              <span>mdi-margin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-markdown" />
              <code class="mr-2">f354</code>
              <span>mdi-markdown</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-marker" />
              <code class="mr-2">f652</code>
              <span>mdi-marker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-marker-check" />
              <code class="mr-2">f355</code>
              <span>mdi-marker-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-martini" />
              <code class="mr-2">f356</code>
              <span>mdi-martini</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-material-ui" />
              <code class="mr-2">f357</code>
              <span>mdi-material-ui</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-math-compass" />
              <code class="mr-2">f358</code>
              <span>mdi-math-compass</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-matrix" />
              <code class="mr-2">f628</code>
              <span>mdi-matrix</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-maxcdn" />
              <code class="mr-2">f359</code>
              <span>mdi-maxcdn</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-medical-bag" />
              <code class="mr-2">f6ee</code>
              <span>mdi-medical-bag</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-medium" />
              <code class="mr-2">f35a</code>
              <span>mdi-medium</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-memory" />
              <code class="mr-2">f35b</code>
              <span>mdi-memory</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu" />
              <code class="mr-2">f35c</code>
              <span>mdi-menu</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu-down" />
              <code class="mr-2">f35d</code>
              <span>mdi-menu-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu-down-outline" />
              <code class="mr-2">f6b5</code>
              <span>mdi-menu-down-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu-left" />
              <code class="mr-2">f35e</code>
              <span>mdi-menu-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu-right" />
              <code class="mr-2">f35f</code>
              <span>mdi-menu-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu-up" />
              <code class="mr-2">f360</code>
              <span>mdi-menu-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-menu-up-outline" />
              <code class="mr-2">f6b6</code>
              <span>mdi-menu-up-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message" />
              <code class="mr-2">f361</code>
              <span>mdi-message</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-alert" />
              <code class="mr-2">f362</code>
              <span>mdi-message-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-bulleted" />
              <code class="mr-2">f6a1</code>
              <span>mdi-message-bulleted</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-bulleted-off" />
              <code class="mr-2">f6a2</code>
              <span>mdi-message-bulleted-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-draw" />
              <code class="mr-2">f363</code>
              <span>mdi-message-draw</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-image" />
              <code class="mr-2">f364</code>
              <span>mdi-message-image</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-outline" />
              <code class="mr-2">f365</code>
              <span>mdi-message-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-plus" />
              <code class="mr-2">f653</code>
              <span>mdi-message-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-processing" />
              <code class="mr-2">f366</code>
              <span>mdi-message-processing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-reply" />
              <code class="mr-2">f367</code>
              <span>mdi-message-reply</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-reply-text" />
              <code class="mr-2">f368</code>
              <span>mdi-message-reply-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-settings" />
              <code class="mr-2">f6ef</code>
              <span>mdi-message-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-settings-variant" />
              <code class="mr-2">f6f0</code>
              <span>mdi-message-settings-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-text" />
              <code class="mr-2">f369</code>
              <span>mdi-message-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-text-outline" />
              <code class="mr-2">f36a</code>
              <span>mdi-message-text-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-message-video" />
              <code class="mr-2">f36b</code>
              <span>mdi-message-video</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-meteor" />
              <code class="mr-2">f629</code>
              <span>mdi-meteor</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microphone" />
              <code class="mr-2">f36c</code>
              <span>mdi-microphone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microphone-off" />
              <code class="mr-2">f36d</code>
              <span>mdi-microphone-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microphone-outline" />
              <code class="mr-2">f36e</code>
              <span>mdi-microphone-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microphone-settings" />
              <code class="mr-2">f36f</code>
              <span>mdi-microphone-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microphone-variant" />
              <code class="mr-2">f370</code>
              <span>mdi-microphone-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microphone-variant-off" />
              <code class="mr-2">f371</code>
              <span>mdi-microphone-variant-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microscope" />
              <code class="mr-2">f654</code>
              <span>mdi-microscope</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-microsoft" />
              <code class="mr-2">f372</code>
              <span>mdi-microsoft</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minecraft" />
              <code class="mr-2">f373</code>
              <span>mdi-minecraft</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minus" />
              <code class="mr-2">f374</code>
              <span>mdi-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minus-box" />
              <code class="mr-2">f375</code>
              <span>mdi-minus-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minus-box-outline" />
              <code class="mr-2">f6f1</code>
              <span>mdi-minus-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minus-circle" />
              <code class="mr-2">f376</code>
              <span>mdi-minus-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minus-circle-outline" />
              <code class="mr-2">f377</code>
              <span>mdi-minus-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-minus-network" />
              <code class="mr-2">f378</code>
              <span>mdi-minus-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mixcloud" />
              <code class="mr-2">f62a</code>
              <span>mdi-mixcloud</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-monitor" />
              <code class="mr-2">f379</code>
              <span>mdi-monitor</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-monitor-multiple" />
              <code class="mr-2">f37a</code>
              <span>mdi-monitor-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-more" />
              <code class="mr-2">f37b</code>
              <span>mdi-more</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-motorbike" />
              <code class="mr-2">f37c</code>
              <span>mdi-motorbike</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mouse" />
              <code class="mr-2">f37d</code>
              <span>mdi-mouse</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mouse-off" />
              <code class="mr-2">f37e</code>
              <span>mdi-mouse-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mouse-variant" />
              <code class="mr-2">f37f</code>
              <span>mdi-mouse-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-mouse-variant-off" />
              <code class="mr-2">f380</code>
              <span>mdi-mouse-variant-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-move-resize" />
              <code class="mr-2">f655</code>
              <span>mdi-move-resize</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-move-resize-variant" />
              <code class="mr-2">f656</code>
              <span>mdi-move-resize-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-movie" />
              <code class="mr-2">f381</code>
              <span>mdi-movie</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-multiplication" />
              <code class="mr-2">f382</code>
              <span>mdi-multiplication</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-multiplication-box" />
              <code class="mr-2">f383</code>
              <span>mdi-multiplication-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-box" />
              <code class="mr-2">f384</code>
              <span>mdi-music-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-box-outline" />
              <code class="mr-2">f385</code>
              <span>mdi-music-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-circle" />
              <code class="mr-2">f386</code>
              <span>mdi-music-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note" />
              <code class="mr-2">f387</code>
              <span>mdi-music-note</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-bluetooth" />
              <code class="mr-2">f5fe</code>
              <span>mdi-music-note-bluetooth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-bluetooth-off" />
              <code class="mr-2">f5ff</code>
              <span>mdi-music-note-bluetooth-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-eighth" />
              <code class="mr-2">f388</code>
              <span>mdi-music-note-eighth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-half" />
              <code class="mr-2">f389</code>
              <span>mdi-music-note-half</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-off" />
              <code class="mr-2">f38a</code>
              <span>mdi-music-note-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-quarter" />
              <code class="mr-2">f38b</code>
              <span>mdi-music-note-quarter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-sixteenth" />
              <code class="mr-2">f38c</code>
              <span>mdi-music-note-sixteenth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-music-note-whole" />
              <code class="mr-2">f38d</code>
              <span>mdi-music-note-whole</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nature" />
              <code class="mr-2">f38e</code>
              <span>mdi-nature</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nature-people" />
              <code class="mr-2">f38f</code>
              <span>mdi-nature-people</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-navigation" />
              <code class="mr-2">f390</code>
              <span>mdi-navigation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-near-me" />
              <code class="mr-2">f5cd</code>
              <span>mdi-near-me</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-needle" />
              <code class="mr-2">f391</code>
              <span>mdi-needle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nest-protect" />
              <code class="mr-2">f392</code>
              <span>mdi-nest-protect</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nest-thermostat" />
              <code class="mr-2">f393</code>
              <span>mdi-nest-thermostat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-network" />
              <code class="mr-2">f6f2</code>
              <span>mdi-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-network-download" />
              <code class="mr-2">f6f3</code>
              <span>mdi-network-download</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-network-question" />
              <code class="mr-2">f6f4</code>
              <span>mdi-network-question</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-network-upload" />
              <code class="mr-2">f6f5</code>
              <span>mdi-network-upload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-new-box" />
              <code class="mr-2">f394</code>
              <span>mdi-new-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-newspaper" />
              <code class="mr-2">f395</code>
              <span>mdi-newspaper</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nfc" />
              <code class="mr-2">f396</code>
              <span>mdi-nfc</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nfc-tap" />
              <code class="mr-2">f397</code>
              <span>mdi-nfc-tap</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nfc-variant" />
              <code class="mr-2">f398</code>
              <span>mdi-nfc-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nodejs" />
              <code class="mr-2">f399</code>
              <span>mdi-nodejs</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note" />
              <code class="mr-2">f39a</code>
              <span>mdi-note</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note-multiple" />
              <code class="mr-2">f6b7</code>
              <span>mdi-note-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note-multiple-outline" />
              <code class="mr-2">f6b8</code>
              <span>mdi-note-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note-outline" />
              <code class="mr-2">f39b</code>
              <span>mdi-note-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note-plus" />
              <code class="mr-2">f39c</code>
              <span>mdi-note-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note-plus-outline" />
              <code class="mr-2">f39d</code>
              <span>mdi-note-plus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-note-text" />
              <code class="mr-2">f39e</code>
              <span>mdi-note-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-notification-clear-all" />
              <code class="mr-2">f39f</code>
              <span>mdi-notification-clear-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-npm" />
              <code class="mr-2">f6f6</code>
              <span>mdi-npm</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nuke" />
              <code class="mr-2">f6a3</code>
              <span>mdi-nuke</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric" />
              <code class="mr-2">f3a0</code>
              <span>mdi-numeric</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-0-box" />
              <code class="mr-2">f3a1</code>
              <span>mdi-numeric-0-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-0-box-multiple-outline" />
              <code class="mr-2">f3a2</code>
              <span>mdi-numeric-0-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-0-box-outline" />
              <code class="mr-2">f3a3</code>
              <span>mdi-numeric-0-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-1-box" />
              <code class="mr-2">f3a4</code>
              <span>mdi-numeric-1-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-1-box-multiple-outline" />
              <code class="mr-2">f3a5</code>
              <span>mdi-numeric-1-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-1-box-outline" />
              <code class="mr-2">f3a6</code>
              <span>mdi-numeric-1-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-2-box" />
              <code class="mr-2">f3a7</code>
              <span>mdi-numeric-2-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-2-box-multiple-outline" />
              <code class="mr-2">f3a8</code>
              <span>mdi-numeric-2-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-2-box-outline" />
              <code class="mr-2">f3a9</code>
              <span>mdi-numeric-2-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-3-box" />
              <code class="mr-2">f3aa</code>
              <span>mdi-numeric-3-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-3-box-multiple-outline" />
              <code class="mr-2">f3ab</code>
              <span>mdi-numeric-3-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-3-box-outline" />
              <code class="mr-2">f3ac</code>
              <span>mdi-numeric-3-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-4-box" />
              <code class="mr-2">f3ad</code>
              <span>mdi-numeric-4-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-4-box-multiple-outline" />
              <code class="mr-2">f3ae</code>
              <span>mdi-numeric-4-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-4-box-outline" />
              <code class="mr-2">f3af</code>
              <span>mdi-numeric-4-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-5-box" />
              <code class="mr-2">f3b0</code>
              <span>mdi-numeric-5-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-5-box-multiple-outline" />
              <code class="mr-2">f3b1</code>
              <span>mdi-numeric-5-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-5-box-outline" />
              <code class="mr-2">f3b2</code>
              <span>mdi-numeric-5-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-6-box" />
              <code class="mr-2">f3b3</code>
              <span>mdi-numeric-6-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-6-box-multiple-outline" />
              <code class="mr-2">f3b4</code>
              <span>mdi-numeric-6-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-6-box-outline" />
              <code class="mr-2">f3b5</code>
              <span>mdi-numeric-6-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-7-box" />
              <code class="mr-2">f3b6</code>
              <span>mdi-numeric-7-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-7-box-multiple-outline" />
              <code class="mr-2">f3b7</code>
              <span>mdi-numeric-7-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-7-box-outline" />
              <code class="mr-2">f3b8</code>
              <span>mdi-numeric-7-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-8-box" />
              <code class="mr-2">f3b9</code>
              <span>mdi-numeric-8-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-8-box-multiple-outline" />
              <code class="mr-2">f3ba</code>
              <span>mdi-numeric-8-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-8-box-outline" />
              <code class="mr-2">f3bb</code>
              <span>mdi-numeric-8-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-9-box" />
              <code class="mr-2">f3bc</code>
              <span>mdi-numeric-9-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-9-box-multiple-outline" />
              <code class="mr-2">f3bd</code>
              <span>mdi-numeric-9-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-9-box-outline" />
              <code class="mr-2">f3be</code>
              <span>mdi-numeric-9-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-9-plus-box" />
              <code class="mr-2">f3bf</code>
              <span>mdi-numeric-9-plus-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-9-plus-box-multiple-outline" />
              <code class="mr-2">f3c0</code>
              <span>mdi-numeric-9-plus-box-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-numeric-9-plus-box-outline" />
              <code class="mr-2">f3c1</code>
              <span>mdi-numeric-9-plus-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nut" />
              <code class="mr-2">f6f7</code>
              <span>mdi-nut</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-nutrition" />
              <code class="mr-2">f3c2</code>
              <span>mdi-nutrition</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-oar" />
              <code class="mr-2">f67b</code>
              <span>mdi-oar</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-octagon" />
              <code class="mr-2">f3c3</code>
              <span>mdi-octagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-octagon-outline" />
              <code class="mr-2">f3c4</code>
              <span>mdi-octagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-octagram" />
              <code class="mr-2">f6f8</code>
              <span>mdi-octagram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-odnoklassniki" />
              <code class="mr-2">f3c5</code>
              <span>mdi-odnoklassniki</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-office" />
              <code class="mr-2">f3c6</code>
              <span>mdi-office</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-oil" />
              <code class="mr-2">f3c7</code>
              <span>mdi-oil</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-oil-temperature" />
              <code class="mr-2">f3c8</code>
              <span>mdi-oil-temperature</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-omega" />
              <code class="mr-2">f3c9</code>
              <span>mdi-omega</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-onedrive" />
              <code class="mr-2">f3ca</code>
              <span>mdi-onedrive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-opacity" />
              <code class="mr-2">f5cc</code>
              <span>mdi-opacity</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-open-in-app" />
              <code class="mr-2">f3cb</code>
              <span>mdi-open-in-app</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-open-in-new" />
              <code class="mr-2">f3cc</code>
              <span>mdi-open-in-new</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-openid" />
              <code class="mr-2">f3cd</code>
              <span>mdi-openid</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-opera" />
              <code class="mr-2">f3ce</code>
              <span>mdi-opera</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ornament" />
              <code class="mr-2">f3cf</code>
              <span>mdi-ornament</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ornament-variant" />
              <code class="mr-2">f3d0</code>
              <span>mdi-ornament-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-owl" />
              <code class="mr-2">f3d2</code>
              <span>mdi-owl</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-package" />
              <code class="mr-2">f3d3</code>
              <span>mdi-package</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-package-down" />
              <code class="mr-2">f3d4</code>
              <span>mdi-package-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-package-up" />
              <code class="mr-2">f3d5</code>
              <span>mdi-package-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-package-variant" />
              <code class="mr-2">f3d6</code>
              <span>mdi-package-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-package-variant-closed" />
              <code class="mr-2">f3d7</code>
              <span>mdi-package-variant-closed</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-first" />
              <code class="mr-2">f600</code>
              <span>mdi-page-first</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-last" />
              <code class="mr-2">f601</code>
              <span>mdi-page-last</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-layout-body" />
              <code class="mr-2">f6f9</code>
              <span>mdi-page-layout-body</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-layout-footer" />
              <code class="mr-2">f6fa</code>
              <span>mdi-page-layout-footer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-layout-header" />
              <code class="mr-2">f6fb</code>
              <span>mdi-page-layout-header</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-layout-sidebar-left" />
              <code class="mr-2">f6fc</code>
              <span>mdi-page-layout-sidebar-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-page-layout-sidebar-right" />
              <code class="mr-2">f6fd</code>
              <span>mdi-page-layout-sidebar-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-palette" />
              <code class="mr-2">f3d8</code>
              <span>mdi-palette</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-palette-advanced" />
              <code class="mr-2">f3d9</code>
              <span>mdi-palette-advanced</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-panda" />
              <code class="mr-2">f3da</code>
              <span>mdi-panda</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pandora" />
              <code class="mr-2">f3db</code>
              <span>mdi-pandora</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-panorama" />
              <code class="mr-2">f3dc</code>
              <span>mdi-panorama</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-panorama-fisheye" />
              <code class="mr-2">f3dd</code>
              <span>mdi-panorama-fisheye</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-panorama-horizontal" />
              <code class="mr-2">f3de</code>
              <span>mdi-panorama-horizontal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-panorama-vertical" />
              <code class="mr-2">f3df</code>
              <span>mdi-panorama-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-panorama-wide-angle" />
              <code class="mr-2">f3e0</code>
              <span>mdi-panorama-wide-angle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-paper-cut-vertical" />
              <code class="mr-2">f3e1</code>
              <span>mdi-paper-cut-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-paperclip" />
              <code class="mr-2">f3e2</code>
              <span>mdi-paperclip</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-parking" />
              <code class="mr-2">f3e3</code>
              <span>mdi-parking</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pause" />
              <code class="mr-2">f3e4</code>
              <span>mdi-pause</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pause-circle" />
              <code class="mr-2">f3e5</code>
              <span>mdi-pause-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pause-circle-outline" />
              <code class="mr-2">f3e6</code>
              <span>mdi-pause-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pause-octagon" />
              <code class="mr-2">f3e7</code>
              <span>mdi-pause-octagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pause-octagon-outline" />
              <code class="mr-2">f3e8</code>
              <span>mdi-pause-octagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-paw" />
              <code class="mr-2">f3e9</code>
              <span>mdi-paw</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-paw-off" />
              <code class="mr-2">f657</code>
              <span>mdi-paw-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pen" />
              <code class="mr-2">f3ea</code>
              <span>mdi-pen</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pencil" />
              <code class="mr-2">f3eb</code>
              <span>mdi-pencil</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pencil-box" />
              <code class="mr-2">f3ec</code>
              <span>mdi-pencil-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pencil-box-outline" />
              <code class="mr-2">f3ed</code>
              <span>mdi-pencil-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pencil-circle" />
              <code class="mr-2">f6fe</code>
              <span>mdi-pencil-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pencil-lock" />
              <code class="mr-2">f3ee</code>
              <span>mdi-pencil-lock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pencil-off" />
              <code class="mr-2">f3ef</code>
              <span>mdi-pencil-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pentagon" />
              <code class="mr-2">f6ff</code>
              <span>mdi-pentagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pentagon-outline" />
              <code class="mr-2">f700</code>
              <span>mdi-pentagon-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-percent" />
              <code class="mr-2">f3f0</code>
              <span>mdi-percent</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pharmacy" />
              <code class="mr-2">f3f1</code>
              <span>mdi-pharmacy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone" />
              <code class="mr-2">f3f2</code>
              <span>mdi-phone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-bluetooth" />
              <code class="mr-2">f3f3</code>
              <span>mdi-phone-bluetooth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-classic" />
              <code class="mr-2">f602</code>
              <span>mdi-phone-classic</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-forward" />
              <code class="mr-2">f3f4</code>
              <span>mdi-phone-forward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-hangup" />
              <code class="mr-2">f3f5</code>
              <span>mdi-phone-hangup</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-in-talk" />
              <code class="mr-2">f3f6</code>
              <span>mdi-phone-in-talk</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-incoming" />
              <code class="mr-2">f3f7</code>
              <span>mdi-phone-incoming</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-locked" />
              <code class="mr-2">f3f8</code>
              <span>mdi-phone-locked</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-log" />
              <code class="mr-2">f3f9</code>
              <span>mdi-phone-log</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-minus" />
              <code class="mr-2">f658</code>
              <span>mdi-phone-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-missed" />
              <code class="mr-2">f3fa</code>
              <span>mdi-phone-missed</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-outgoing" />
              <code class="mr-2">f3fb</code>
              <span>mdi-phone-outgoing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-paused" />
              <code class="mr-2">f3fc</code>
              <span>mdi-phone-paused</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-plus" />
              <code class="mr-2">f659</code>
              <span>mdi-phone-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-settings" />
              <code class="mr-2">f3fd</code>
              <span>mdi-phone-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-phone-voip" />
              <code class="mr-2">f3fe</code>
              <span>mdi-phone-voip</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pi" />
              <code class="mr-2">f3ff</code>
              <span>mdi-pi</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pi-box" />
              <code class="mr-2">f400</code>
              <span>mdi-pi-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-piano" />
              <code class="mr-2">f67c</code>
              <span>mdi-piano</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pig" />
              <code class="mr-2">f401</code>
              <span>mdi-pig</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pill" />
              <code class="mr-2">f402</code>
              <span>mdi-pill</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pillar" />
              <code class="mr-2">f701</code>
              <span>mdi-pillar</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pin" />
              <code class="mr-2">f403</code>
              <span>mdi-pin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pin-off" />
              <code class="mr-2">f404</code>
              <span>mdi-pin-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pine-tree" />
              <code class="mr-2">f405</code>
              <span>mdi-pine-tree</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pine-tree-box" />
              <code class="mr-2">f406</code>
              <span>mdi-pine-tree-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pinterest" />
              <code class="mr-2">f407</code>
              <span>mdi-pinterest</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pinterest-box" />
              <code class="mr-2">f408</code>
              <span>mdi-pinterest-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pistol" />
              <code class="mr-2">f702</code>
              <span>mdi-pistol</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pizza" />
              <code class="mr-2">f409</code>
              <span>mdi-pizza</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plane-shield" />
              <code class="mr-2">f6ba</code>
              <span>mdi-plane-shield</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-play" />
              <code class="mr-2">f40a</code>
              <span>mdi-play</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-play-box-outline" />
              <code class="mr-2">f40b</code>
              <span>mdi-play-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-play-circle" />
              <code class="mr-2">f40c</code>
              <span>mdi-play-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-play-circle-outline" />
              <code class="mr-2">f40d</code>
              <span>mdi-play-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-play-pause" />
              <code class="mr-2">f40e</code>
              <span>mdi-play-pause</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-play-protected-content" />
              <code class="mr-2">f40f</code>
              <span>mdi-play-protected-content</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-playlist-check" />
              <code class="mr-2">f5c7</code>
              <span>mdi-playlist-check</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-playlist-minus" />
              <code class="mr-2">f410</code>
              <span>mdi-playlist-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-playlist-play" />
              <code class="mr-2">f411</code>
              <span>mdi-playlist-play</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-playlist-plus" />
              <code class="mr-2">f412</code>
              <span>mdi-playlist-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-playlist-remove" />
              <code class="mr-2">f413</code>
              <span>mdi-playlist-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-playstation" />
              <code class="mr-2">f414</code>
              <span>mdi-playstation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plex" />
              <code class="mr-2">f6b9</code>
              <span>mdi-plex</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus" />
              <code class="mr-2">f415</code>
              <span>mdi-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-box" />
              <code class="mr-2">f416</code>
              <span>mdi-plus-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-box-outline" />
              <code class="mr-2">f703</code>
              <span>mdi-plus-box-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-circle" />
              <code class="mr-2">f417</code>
              <span>mdi-plus-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-circle-multiple-outline" />
              <code class="mr-2">f418</code>
              <span>mdi-plus-circle-multiple-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-circle-outline" />
              <code class="mr-2">f419</code>
              <span>mdi-plus-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-network" />
              <code class="mr-2">f41a</code>
              <span>mdi-plus-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-one" />
              <code class="mr-2">f41b</code>
              <span>mdi-plus-one</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-plus-outline" />
              <code class="mr-2">f704</code>
              <span>mdi-plus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pocket" />
              <code class="mr-2">f41c</code>
              <span>mdi-pocket</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pokeball" />
              <code class="mr-2">f41d</code>
              <span>mdi-pokeball</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-polaroid" />
              <code class="mr-2">f41e</code>
              <span>mdi-polaroid</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-poll" />
              <code class="mr-2">f41f</code>
              <span>mdi-poll</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-poll-box" />
              <code class="mr-2">f420</code>
              <span>mdi-poll-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-polymer" />
              <code class="mr-2">f421</code>
              <span>mdi-polymer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pool" />
              <code class="mr-2">f606</code>
              <span>mdi-pool</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-popcorn" />
              <code class="mr-2">f422</code>
              <span>mdi-popcorn</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pot" />
              <code class="mr-2">f65a</code>
              <span>mdi-pot</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pot-mix" />
              <code class="mr-2">f65b</code>
              <span>mdi-pot-mix</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pound" />
              <code class="mr-2">f423</code>
              <span>mdi-pound</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pound-box" />
              <code class="mr-2">f424</code>
              <span>mdi-pound-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-power" />
              <code class="mr-2">f425</code>
              <span>mdi-power</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-power-plug" />
              <code class="mr-2">f6a4</code>
              <span>mdi-power-plug</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-power-plug-off" />
              <code class="mr-2">f6a5</code>
              <span>mdi-power-plug-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-power-settings" />
              <code class="mr-2">f426</code>
              <span>mdi-power-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-power-socket" />
              <code class="mr-2">f427</code>
              <span>mdi-power-socket</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-prescription" />
              <code class="mr-2">f705</code>
              <span>mdi-prescription</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-presentation" />
              <code class="mr-2">f428</code>
              <span>mdi-presentation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-presentation-play" />
              <code class="mr-2">f429</code>
              <span>mdi-presentation-play</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-printer" />
              <code class="mr-2">f42a</code>
              <span>mdi-printer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-printer-3d" />
              <code class="mr-2">f42b</code>
              <span>mdi-printer-3d</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-printer-alert" />
              <code class="mr-2">f42c</code>
              <span>mdi-printer-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-printer-settings" />
              <code class="mr-2">f706</code>
              <span>mdi-printer-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-priority-high" />
              <code class="mr-2">f603</code>
              <span>mdi-priority-high</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-priority-low" />
              <code class="mr-2">f604</code>
              <span>mdi-priority-low</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-professional-hexagon" />
              <code class="mr-2">f42d</code>
              <span>mdi-professional-hexagon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-projector" />
              <code class="mr-2">f42e</code>
              <span>mdi-projector</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-projector-screen" />
              <code class="mr-2">f42f</code>
              <span>mdi-projector-screen</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-publish" />
              <code class="mr-2">f6a6</code>
              <span>mdi-publish</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-pulse" />
              <code class="mr-2">f430</code>
              <span>mdi-pulse</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-puzzle" />
              <code class="mr-2">f431</code>
              <span>mdi-puzzle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-qqchat" />
              <code class="mr-2">f605</code>
              <span>mdi-qqchat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-qrcode" />
              <code class="mr-2">f432</code>
              <span>mdi-qrcode</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-qrcode-scan" />
              <code class="mr-2">f433</code>
              <span>mdi-qrcode-scan</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-quadcopter" />
              <code class="mr-2">f434</code>
              <span>mdi-quadcopter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-quality-high" />
              <code class="mr-2">f435</code>
              <span>mdi-quality-high</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-quicktime" />
              <code class="mr-2">f436</code>
              <span>mdi-quicktime</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radar" />
              <code class="mr-2">f437</code>
              <span>mdi-radar</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radiator" />
              <code class="mr-2">f438</code>
              <span>mdi-radiator</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radio" />
              <code class="mr-2">f439</code>
              <span>mdi-radio</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radio-handheld" />
              <code class="mr-2">f43a</code>
              <span>mdi-radio-handheld</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radio-tower" />
              <code class="mr-2">f43b</code>
              <span>mdi-radio-tower</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radioactive" />
              <code class="mr-2">f43c</code>
              <span>mdi-radioactive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radiobox-blank" />
              <code class="mr-2">f43d</code>
              <span>mdi-radiobox-blank</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-radiobox-marked" />
              <code class="mr-2">f43e</code>
              <span>mdi-radiobox-marked</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-raspberrypi" />
              <code class="mr-2">f43f</code>
              <span>mdi-raspberrypi</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ray-end" />
              <code class="mr-2">f440</code>
              <span>mdi-ray-end</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ray-end-arrow" />
              <code class="mr-2">f441</code>
              <span>mdi-ray-end-arrow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ray-start" />
              <code class="mr-2">f442</code>
              <span>mdi-ray-start</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ray-start-arrow" />
              <code class="mr-2">f443</code>
              <span>mdi-ray-start-arrow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ray-start-end" />
              <code class="mr-2">f444</code>
              <span>mdi-ray-start-end</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ray-vertex" />
              <code class="mr-2">f445</code>
              <span>mdi-ray-vertex</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rdio" />
              <code class="mr-2">f446</code>
              <span>mdi-rdio</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-react" />
              <code class="mr-2">f707</code>
              <span>mdi-react</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-read" />
              <code class="mr-2">f447</code>
              <span>mdi-read</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-readability" />
              <code class="mr-2">f448</code>
              <span>mdi-readability</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-receipt" />
              <code class="mr-2">f449</code>
              <span>mdi-receipt</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-record" />
              <code class="mr-2">f44a</code>
              <span>mdi-record</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-record-rec" />
              <code class="mr-2">f44b</code>
              <span>mdi-record-rec</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-recycle" />
              <code class="mr-2">f44c</code>
              <span>mdi-recycle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reddit" />
              <code class="mr-2">f44d</code>
              <span>mdi-reddit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-redo" />
              <code class="mr-2">f44e</code>
              <span>mdi-redo</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-redo-variant" />
              <code class="mr-2">f44f</code>
              <span>mdi-redo-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-refresh" />
              <code class="mr-2">f450</code>
              <span>mdi-refresh</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-regex" />
              <code class="mr-2">f451</code>
              <span>mdi-regex</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-relative-scale" />
              <code class="mr-2">f452</code>
              <span>mdi-relative-scale</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reload" />
              <code class="mr-2">f453</code>
              <span>mdi-reload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-remote" />
              <code class="mr-2">f454</code>
              <span>mdi-remote</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rename-box" />
              <code class="mr-2">f455</code>
              <span>mdi-rename-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reorder-horizontal" />
              <code class="mr-2">f687</code>
              <span>mdi-reorder-horizontal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reorder-vertical" />
              <code class="mr-2">f688</code>
              <span>mdi-reorder-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-repeat" />
              <code class="mr-2">f456</code>
              <span>mdi-repeat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-repeat-off" />
              <code class="mr-2">f457</code>
              <span>mdi-repeat-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-repeat-once" />
              <code class="mr-2">f458</code>
              <span>mdi-repeat-once</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-replay" />
              <code class="mr-2">f459</code>
              <span>mdi-replay</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reply" />
              <code class="mr-2">f45a</code>
              <span>mdi-reply</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reply-all" />
              <code class="mr-2">f45b</code>
              <span>mdi-reply-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-reproduction" />
              <code class="mr-2">f45c</code>
              <span>mdi-reproduction</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-resize-bottom-right" />
              <code class="mr-2">f45d</code>
              <span>mdi-resize-bottom-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-responsive" />
              <code class="mr-2">f45e</code>
              <span>mdi-responsive</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-restart" />
              <code class="mr-2">f708</code>
              <span>mdi-restart</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-restore" />
              <code class="mr-2">f6a7</code>
              <span>mdi-restore</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rewind" />
              <code class="mr-2">f45f</code>
              <span>mdi-rewind</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rewind-outline" />
              <code class="mr-2">f709</code>
              <span>mdi-rewind-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rhombus" />
              <code class="mr-2">f70a</code>
              <span>mdi-rhombus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rhombus-outline" />
              <code class="mr-2">f70b</code>
              <span>mdi-rhombus-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ribbon" />
              <code class="mr-2">f460</code>
              <span>mdi-ribbon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-road" />
              <code class="mr-2">f461</code>
              <span>mdi-road</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-road-variant" />
              <code class="mr-2">f462</code>
              <span>mdi-road-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-robot" />
              <code class="mr-2">f6a8</code>
              <span>mdi-robot</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rocket" />
              <code class="mr-2">f463</code>
              <span>mdi-rocket</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-roomba" />
              <code class="mr-2">f70c</code>
              <span>mdi-roomba</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rotate-3d" />
              <code class="mr-2">f464</code>
              <span>mdi-rotate-3d</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rotate-left" />
              <code class="mr-2">f465</code>
              <span>mdi-rotate-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rotate-left-variant" />
              <code class="mr-2">f466</code>
              <span>mdi-rotate-left-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rotate-right" />
              <code class="mr-2">f467</code>
              <span>mdi-rotate-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rotate-right-variant" />
              <code class="mr-2">f468</code>
              <span>mdi-rotate-right-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rounded-corner" />
              <code class="mr-2">f607</code>
              <span>mdi-rounded-corner</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-router-wireless" />
              <code class="mr-2">f469</code>
              <span>mdi-router-wireless</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-routes" />
              <code class="mr-2">f46a</code>
              <span>mdi-routes</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rowing" />
              <code class="mr-2">f608</code>
              <span>mdi-rowing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rss" />
              <code class="mr-2">f46b</code>
              <span>mdi-rss</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-rss-box" />
              <code class="mr-2">f46c</code>
              <span>mdi-rss-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ruler" />
              <code class="mr-2">f46d</code>
              <span>mdi-ruler</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-run" />
              <code class="mr-2">f70d</code>
              <span>mdi-run</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-run-fast" />
              <code class="mr-2">f46e</code>
              <span>mdi-run-fast</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sale" />
              <code class="mr-2">f46f</code>
              <span>mdi-sale</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-satellite" />
              <code class="mr-2">f470</code>
              <span>mdi-satellite</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-satellite-variant" />
              <code class="mr-2">f471</code>
              <span>mdi-satellite-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-saxophone" />
              <code class="mr-2">f609</code>
              <span>mdi-saxophone</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-scale" />
              <code class="mr-2">f472</code>
              <span>mdi-scale</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-scale-balance" />
              <code class="mr-2">f5d1</code>
              <span>mdi-scale-balance</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-scale-bathroom" />
              <code class="mr-2">f473</code>
              <span>mdi-scale-bathroom</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-scanner" />
              <code class="mr-2">f6aa</code>
              <span>mdi-scanner</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-school" />
              <code class="mr-2">f474</code>
              <span>mdi-school</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-screen-rotation" />
              <code class="mr-2">f475</code>
              <span>mdi-screen-rotation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-screen-rotation-lock" />
              <code class="mr-2">f476</code>
              <span>mdi-screen-rotation-lock</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-screwdriver" />
              <code class="mr-2">f477</code>
              <span>mdi-screwdriver</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-script" />
              <code class="mr-2">f478</code>
              <span>mdi-script</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sd" />
              <code class="mr-2">f479</code>
              <span>mdi-sd</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seal" />
              <code class="mr-2">f47a</code>
              <span>mdi-seal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-search-web" />
              <code class="mr-2">f70e</code>
              <span>mdi-search-web</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-flat" />
              <code class="mr-2">f47b</code>
              <span>mdi-seat-flat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-flat-angled" />
              <code class="mr-2">f47c</code>
              <span>mdi-seat-flat-angled</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-individual-suite" />
              <code class="mr-2">f47d</code>
              <span>mdi-seat-individual-suite</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-legroom-extra" />
              <code class="mr-2">f47e</code>
              <span>mdi-seat-legroom-extra</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-legroom-normal" />
              <code class="mr-2">f47f</code>
              <span>mdi-seat-legroom-normal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-legroom-reduced" />
              <code class="mr-2">f480</code>
              <span>mdi-seat-legroom-reduced</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-recline-extra" />
              <code class="mr-2">f481</code>
              <span>mdi-seat-recline-extra</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-seat-recline-normal" />
              <code class="mr-2">f482</code>
              <span>mdi-seat-recline-normal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-security" />
              <code class="mr-2">f483</code>
              <span>mdi-security</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-security-home" />
              <code class="mr-2">f689</code>
              <span>mdi-security-home</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-security-network" />
              <code class="mr-2">f484</code>
              <span>mdi-security-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-select" />
              <code class="mr-2">f485</code>
              <span>mdi-select</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-select-all" />
              <code class="mr-2">f486</code>
              <span>mdi-select-all</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-select-inverse" />
              <code class="mr-2">f487</code>
              <span>mdi-select-inverse</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-select-off" />
              <code class="mr-2">f488</code>
              <span>mdi-select-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-selection" />
              <code class="mr-2">f489</code>
              <span>mdi-selection</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-send" />
              <code class="mr-2">f48a</code>
              <span>mdi-send</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-serial-port" />
              <code class="mr-2">f65c</code>
              <span>mdi-serial-port</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server" />
              <code class="mr-2">f48b</code>
              <span>mdi-server</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-minus" />
              <code class="mr-2">f48c</code>
              <span>mdi-server-minus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-network" />
              <code class="mr-2">f48d</code>
              <span>mdi-server-network</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-network-off" />
              <code class="mr-2">f48e</code>
              <span>mdi-server-network-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-off" />
              <code class="mr-2">f48f</code>
              <span>mdi-server-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-plus" />
              <code class="mr-2">f490</code>
              <span>mdi-server-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-remove" />
              <code class="mr-2">f491</code>
              <span>mdi-server-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-server-security" />
              <code class="mr-2">f492</code>
              <span>mdi-server-security</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-settings" />
              <code class="mr-2">f493</code>
              <span>mdi-settings</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-settings-box" />
              <code class="mr-2">f494</code>
              <span>mdi-settings-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shape-circle-plus" />
              <code class="mr-2">f65d</code>
              <span>mdi-shape-circle-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shape-plus" />
              <code class="mr-2">f495</code>
              <span>mdi-shape-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shape-polygon-plus" />
              <code class="mr-2">f65e</code>
              <span>mdi-shape-polygon-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shape-rectangle-plus" />
              <code class="mr-2">f65f</code>
              <span>mdi-shape-rectangle-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shape-square-plus" />
              <code class="mr-2">f660</code>
              <span>mdi-shape-square-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-share" />
              <code class="mr-2">f496</code>
              <span>mdi-share</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-share-variant" />
              <code class="mr-2">f497</code>
              <span>mdi-share-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shield" />
              <code class="mr-2">f498</code>
              <span>mdi-shield</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shield-outline" />
              <code class="mr-2">f499</code>
              <span>mdi-shield-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shopping" />
              <code class="mr-2">f49a</code>
              <span>mdi-shopping</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shopping-music" />
              <code class="mr-2">f49b</code>
              <span>mdi-shopping-music</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shovel" />
              <code class="mr-2">f70f</code>
              <span>mdi-shovel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shovel-off" />
              <code class="mr-2">f710</code>
              <span>mdi-shovel-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shredder" />
              <code class="mr-2">f49c</code>
              <span>mdi-shredder</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shuffle" />
              <code class="mr-2">f49d</code>
              <span>mdi-shuffle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shuffle-disabled" />
              <code class="mr-2">f49e</code>
              <span>mdi-shuffle-disabled</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-shuffle-variant" />
              <code class="mr-2">f49f</code>
              <span>mdi-shuffle-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sigma" />
              <code class="mr-2">f4a0</code>
              <span>mdi-sigma</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sigma-lower" />
              <code class="mr-2">f62b</code>
              <span>mdi-sigma-lower</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sign-caution" />
              <code class="mr-2">f4a1</code>
              <span>mdi-sign-caution</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal" />
              <code class="mr-2">f4a2</code>
              <span>mdi-signal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal-2g" />
              <code class="mr-2">f711</code>
              <span>mdi-signal-2g</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal-3g" />
              <code class="mr-2">f712</code>
              <span>mdi-signal-3g</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal-4g" />
              <code class="mr-2">f713</code>
              <span>mdi-signal-4g</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal-hspa" />
              <code class="mr-2">f714</code>
              <span>mdi-signal-hspa</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal-hspa-plus" />
              <code class="mr-2">f715</code>
              <span>mdi-signal-hspa-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-signal-variant" />
              <code class="mr-2">f60a</code>
              <span>mdi-signal-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-silverware" />
              <code class="mr-2">f4a3</code>
              <span>mdi-silverware</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-silverware-fork" />
              <code class="mr-2">f4a4</code>
              <span>mdi-silverware-fork</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-silverware-spoon" />
              <code class="mr-2">f4a5</code>
              <span>mdi-silverware-spoon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-silverware-variant" />
              <code class="mr-2">f4a6</code>
              <span>mdi-silverware-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sim" />
              <code class="mr-2">f4a7</code>
              <span>mdi-sim</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sim-alert" />
              <code class="mr-2">f4a8</code>
              <span>mdi-sim-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sim-off" />
              <code class="mr-2">f4a9</code>
              <span>mdi-sim-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sitemap" />
              <code class="mr-2">f4aa</code>
              <span>mdi-sitemap</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-backward" />
              <code class="mr-2">f4ab</code>
              <span>mdi-skip-backward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-forward" />
              <code class="mr-2">f4ac</code>
              <span>mdi-skip-forward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-next" />
              <code class="mr-2">f4ad</code>
              <span>mdi-skip-next</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-next-circle" />
              <code class="mr-2">f661</code>
              <span>mdi-skip-next-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-next-circle-outline" />
              <code class="mr-2">f662</code>
              <span>mdi-skip-next-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-previous" />
              <code class="mr-2">f4ae</code>
              <span>mdi-skip-previous</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-previous-circle" />
              <code class="mr-2">f663</code>
              <span>mdi-skip-previous-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skip-previous-circle-outline" />
              <code class="mr-2">f664</code>
              <span>mdi-skip-previous-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skull" />
              <code class="mr-2">f68b</code>
              <span>mdi-skull</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skype" />
              <code class="mr-2">f4af</code>
              <span>mdi-skype</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-skype-business" />
              <code class="mr-2">f4b0</code>
              <span>mdi-skype-business</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-slack" />
              <code class="mr-2">f4b1</code>
              <span>mdi-slack</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sleep" />
              <code class="mr-2">f4b2</code>
              <span>mdi-sleep</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sleep-off" />
              <code class="mr-2">f4b3</code>
              <span>mdi-sleep-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-smoking" />
              <code class="mr-2">f4b4</code>
              <span>mdi-smoking</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-smoking-off" />
              <code class="mr-2">f4b5</code>
              <span>mdi-smoking-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-snapchat" />
              <code class="mr-2">f4b6</code>
              <span>mdi-snapchat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-snowflake" />
              <code class="mr-2">f716</code>
              <span>mdi-snowflake</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-snowman" />
              <code class="mr-2">f4b7</code>
              <span>mdi-snowman</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-soccer" />
              <code class="mr-2">f4b8</code>
              <span>mdi-soccer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sofa" />
              <code class="mr-2">f4b9</code>
              <span>mdi-sofa</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-solid" />
              <code class="mr-2">f68c</code>
              <span>mdi-solid</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sort" />
              <code class="mr-2">f4ba</code>
              <span>mdi-sort</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sort-alphabetical" />
              <code class="mr-2">f4bb</code>
              <span>mdi-sort-alphabetical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sort-ascending" />
              <code class="mr-2">f4bc</code>
              <span>mdi-sort-ascending</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sort-descending" />
              <code class="mr-2">f4bd</code>
              <span>mdi-sort-descending</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sort-numeric" />
              <code class="mr-2">f4be</code>
              <span>mdi-sort-numeric</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sort-variant" />
              <code class="mr-2">f4bf</code>
              <span>mdi-sort-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-soundcloud" />
              <code class="mr-2">f4c0</code>
              <span>mdi-soundcloud</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-branch" />
              <code class="mr-2">f62c</code>
              <span>mdi-source-branch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit" />
              <code class="mr-2">f717</code>
              <span>mdi-source-commit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit-end" />
              <code class="mr-2">f718</code>
              <span>mdi-source-commit-end</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit-end-local" />
              <code class="mr-2">f719</code>
              <span>mdi-source-commit-end-local</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit-local" />
              <code class="mr-2">f71a</code>
              <span>mdi-source-commit-local</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit-next-local" />
              <code class="mr-2">f71b</code>
              <span>mdi-source-commit-next-local</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit-start" />
              <code class="mr-2">f71c</code>
              <span>mdi-source-commit-start</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-commit-start-next-local" />
              <code class="mr-2">f71d</code>
              <span>mdi-source-commit-start-next-local</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-fork" />
              <code class="mr-2">f4c1</code>
              <span>mdi-source-fork</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-merge" />
              <code class="mr-2">f62d</code>
              <span>mdi-source-merge</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-source-pull" />
              <code class="mr-2">f4c2</code>
              <span>mdi-source-pull</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-speaker" />
              <code class="mr-2">f4c3</code>
              <span>mdi-speaker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-speaker-off" />
              <code class="mr-2">f4c4</code>
              <span>mdi-speaker-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-speaker-wireless" />
              <code class="mr-2">f71e</code>
              <span>mdi-speaker-wireless</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-speedometer" />
              <code class="mr-2">f4c5</code>
              <span>mdi-speedometer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-spellcheck" />
              <code class="mr-2">f4c6</code>
              <span>mdi-spellcheck</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-spotify" />
              <code class="mr-2">f4c7</code>
              <span>mdi-spotify</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-spotlight" />
              <code class="mr-2">f4c8</code>
              <span>mdi-spotlight</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-spotlight-beam" />
              <code class="mr-2">f4c9</code>
              <span>mdi-spotlight-beam</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-spray" />
              <code class="mr-2">f665</code>
              <span>mdi-spray</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-square-inc" />
              <code class="mr-2">f4ca</code>
              <span>mdi-square-inc</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-square-inc-cash" />
              <code class="mr-2">f4cb</code>
              <span>mdi-square-inc-cash</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stackexchange" />
              <code class="mr-2">f60b</code>
              <span>mdi-stackexchange</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stackoverflow" />
              <code class="mr-2">f4cc</code>
              <span>mdi-stackoverflow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stadium" />
              <code class="mr-2">f71f</code>
              <span>mdi-stadium</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stairs" />
              <code class="mr-2">f4cd</code>
              <span>mdi-stairs</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-star" />
              <code class="mr-2">f4ce</code>
              <span>mdi-star</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-star-circle" />
              <code class="mr-2">f4cf</code>
              <span>mdi-star-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-star-half" />
              <code class="mr-2">f4d0</code>
              <span>mdi-star-half</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-star-off" />
              <code class="mr-2">f4d1</code>
              <span>mdi-star-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-star-outline" />
              <code class="mr-2">f4d2</code>
              <span>mdi-star-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-steam" />
              <code class="mr-2">f4d3</code>
              <span>mdi-steam</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-steering" />
              <code class="mr-2">f4d4</code>
              <span>mdi-steering</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-step-backward" />
              <code class="mr-2">f4d5</code>
              <span>mdi-step-backward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-step-backward-2" />
              <code class="mr-2">f4d6</code>
              <span>mdi-step-backward-2</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-step-forward" />
              <code class="mr-2">f4d7</code>
              <span>mdi-step-forward</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-step-forward-2" />
              <code class="mr-2">f4d8</code>
              <span>mdi-step-forward-2</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stethoscope" />
              <code class="mr-2">f4d9</code>
              <span>mdi-stethoscope</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sticker" />
              <code class="mr-2">f5d0</code>
              <span>mdi-sticker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stocking" />
              <code class="mr-2">f4da</code>
              <span>mdi-stocking</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stop" />
              <code class="mr-2">f4db</code>
              <span>mdi-stop</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stop-circle" />
              <code class="mr-2">f666</code>
              <span>mdi-stop-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stop-circle-outline" />
              <code class="mr-2">f667</code>
              <span>mdi-stop-circle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-store" />
              <code class="mr-2">f4dc</code>
              <span>mdi-store</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-store-24-hour" />
              <code class="mr-2">f4dd</code>
              <span>mdi-store-24-hour</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-stove" />
              <code class="mr-2">f4de</code>
              <span>mdi-stove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-subdirectory-arrow-left" />
              <code class="mr-2">f60c</code>
              <span>mdi-subdirectory-arrow-left</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-subdirectory-arrow-right" />
              <code class="mr-2">f60d</code>
              <span>mdi-subdirectory-arrow-right</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-subway" />
              <code class="mr-2">f6ab</code>
              <span>mdi-subway</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-subway-variant" />
              <code class="mr-2">f4df</code>
              <span>mdi-subway-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sunglasses" />
              <code class="mr-2">f4e0</code>
              <span>mdi-sunglasses</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-surround-sound" />
              <code class="mr-2">f5c5</code>
              <span>mdi-surround-sound</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-svg" />
              <code class="mr-2">f720</code>
              <span>mdi-svg</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-swap-horizontal" />
              <code class="mr-2">f4e1</code>
              <span>mdi-swap-horizontal</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-swap-vertical" />
              <code class="mr-2">f4e2</code>
              <span>mdi-swap-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-swim" />
              <code class="mr-2">f4e3</code>
              <span>mdi-swim</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-switch" />
              <code class="mr-2">f4e4</code>
              <span>mdi-switch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sword" />
              <code class="mr-2">f4e5</code>
              <span>mdi-sword</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sync" />
              <code class="mr-2">f4e6</code>
              <span>mdi-sync</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sync-alert" />
              <code class="mr-2">f4e7</code>
              <span>mdi-sync-alert</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-sync-off" />
              <code class="mr-2">f4e8</code>
              <span>mdi-sync-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tab" />
              <code class="mr-2">f4e9</code>
              <span>mdi-tab</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tab-unselected" />
              <code class="mr-2">f4ea</code>
              <span>mdi-tab-unselected</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table" />
              <code class="mr-2">f4eb</code>
              <span>mdi-table</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-column-plus-after" />
              <code class="mr-2">f4ec</code>
              <span>mdi-table-column-plus-after</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-column-plus-before" />
              <code class="mr-2">f4ed</code>
              <span>mdi-table-column-plus-before</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-column-remove" />
              <code class="mr-2">f4ee</code>
              <span>mdi-table-column-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-column-width" />
              <code class="mr-2">f4ef</code>
              <span>mdi-table-column-width</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-edit" />
              <code class="mr-2">f4f0</code>
              <span>mdi-table-edit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-large" />
              <code class="mr-2">f4f1</code>
              <span>mdi-table-large</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-row-height" />
              <code class="mr-2">f4f2</code>
              <span>mdi-table-row-height</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-row-plus-after" />
              <code class="mr-2">f4f3</code>
              <span>mdi-table-row-plus-after</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-row-plus-before" />
              <code class="mr-2">f4f4</code>
              <span>mdi-table-row-plus-before</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-table-row-remove" />
              <code class="mr-2">f4f5</code>
              <span>mdi-table-row-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tablet" />
              <code class="mr-2">f4f6</code>
              <span>mdi-tablet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tablet-android" />
              <code class="mr-2">f4f7</code>
              <span>mdi-tablet-android</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tablet-ipad" />
              <code class="mr-2">f4f8</code>
              <span>mdi-tablet-ipad</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag" />
              <code class="mr-2">f4f9</code>
              <span>mdi-tag</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-faces" />
              <code class="mr-2">f4fa</code>
              <span>mdi-tag-faces</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-heart" />
              <code class="mr-2">f68a</code>
              <span>mdi-tag-heart</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-multiple" />
              <code class="mr-2">f4fb</code>
              <span>mdi-tag-multiple</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-outline" />
              <code class="mr-2">f4fc</code>
              <span>mdi-tag-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-plus" />
              <code class="mr-2">f721</code>
              <span>mdi-tag-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-remove" />
              <code class="mr-2">f722</code>
              <span>mdi-tag-remove</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tag-text-outline" />
              <code class="mr-2">f4fd</code>
              <span>mdi-tag-text-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-target" />
              <code class="mr-2">f4fe</code>
              <span>mdi-target</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-taxi" />
              <code class="mr-2">f4ff</code>
              <span>mdi-taxi</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-teamviewer" />
              <code class="mr-2">f500</code>
              <span>mdi-teamviewer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-telegram" />
              <code class="mr-2">f501</code>
              <span>mdi-telegram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-television" />
              <code class="mr-2">f502</code>
              <span>mdi-television</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-television-guide" />
              <code class="mr-2">f503</code>
              <span>mdi-television-guide</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-temperature-celsius" />
              <code class="mr-2">f504</code>
              <span>mdi-temperature-celsius</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-temperature-fahrenheit" />
              <code class="mr-2">f505</code>
              <span>mdi-temperature-fahrenheit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-temperature-kelvin" />
              <code class="mr-2">f506</code>
              <span>mdi-temperature-kelvin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tennis" />
              <code class="mr-2">f507</code>
              <span>mdi-tennis</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tent" />
              <code class="mr-2">f508</code>
              <span>mdi-tent</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-terrain" />
              <code class="mr-2">f509</code>
              <span>mdi-terrain</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-test-tube" />
              <code class="mr-2">f668</code>
              <span>mdi-test-tube</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-text-shadow" />
              <code class="mr-2">f669</code>
              <span>mdi-text-shadow</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-text-to-speech" />
              <code class="mr-2">f50a</code>
              <span>mdi-text-to-speech</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-text-to-speech-off" />
              <code class="mr-2">f50b</code>
              <span>mdi-text-to-speech-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-textbox" />
              <code class="mr-2">f60e</code>
              <span>mdi-textbox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-texture" />
              <code class="mr-2">f50c</code>
              <span>mdi-texture</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-theater" />
              <code class="mr-2">f50d</code>
              <span>mdi-theater</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-theme-light-dark" />
              <code class="mr-2">f50e</code>
              <span>mdi-theme-light-dark</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thermometer" />
              <code class="mr-2">f50f</code>
              <span>mdi-thermometer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thermometer-lines" />
              <code class="mr-2">f510</code>
              <span>mdi-thermometer-lines</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thumb-down" />
              <code class="mr-2">f511</code>
              <span>mdi-thumb-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thumb-down-outline" />
              <code class="mr-2">f512</code>
              <span>mdi-thumb-down-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thumb-up" />
              <code class="mr-2">f513</code>
              <span>mdi-thumb-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thumb-up-outline" />
              <code class="mr-2">f514</code>
              <span>mdi-thumb-up-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-thumbs-up-down" />
              <code class="mr-2">f515</code>
              <span>mdi-thumbs-up-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ticket" />
              <code class="mr-2">f516</code>
              <span>mdi-ticket</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ticket-account" />
              <code class="mr-2">f517</code>
              <span>mdi-ticket-account</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ticket-confirmation" />
              <code class="mr-2">f518</code>
              <span>mdi-ticket-confirmation</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ticket-percent" />
              <code class="mr-2">f723</code>
              <span>mdi-ticket-percent</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tie" />
              <code class="mr-2">f519</code>
              <span>mdi-tie</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tilde" />
              <code class="mr-2">f724</code>
              <span>mdi-tilde</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timelapse" />
              <code class="mr-2">f51a</code>
              <span>mdi-timelapse</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timer" />
              <code class="mr-2">f51b</code>
              <span>mdi-timer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timer-10" />
              <code class="mr-2">f51c</code>
              <span>mdi-timer-10</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timer-3" />
              <code class="mr-2">f51d</code>
              <span>mdi-timer-3</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timer-off" />
              <code class="mr-2">f51e</code>
              <span>mdi-timer-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timer-sand" />
              <code class="mr-2">f51f</code>
              <span>mdi-timer-sand</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timer-sand-empty" />
              <code class="mr-2">f6ac</code>
              <span>mdi-timer-sand-empty</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-timetable" />
              <code class="mr-2">f520</code>
              <span>mdi-timetable</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-toggle-switch" />
              <code class="mr-2">f521</code>
              <span>mdi-toggle-switch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-toggle-switch-off" />
              <code class="mr-2">f522</code>
              <span>mdi-toggle-switch-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooltip" />
              <code class="mr-2">f523</code>
              <span>mdi-tooltip</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooltip-edit" />
              <code class="mr-2">f524</code>
              <span>mdi-tooltip-edit</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooltip-image" />
              <code class="mr-2">f525</code>
              <span>mdi-tooltip-image</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooltip-outline" />
              <code class="mr-2">f526</code>
              <span>mdi-tooltip-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooltip-outline-plus" />
              <code class="mr-2">f527</code>
              <span>mdi-tooltip-outline-plus</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooltip-text" />
              <code class="mr-2">f528</code>
              <span>mdi-tooltip-text</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tooth" />
              <code class="mr-2">f529</code>
              <span>mdi-tooth</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tor" />
              <code class="mr-2">f52a</code>
              <span>mdi-tor</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tower-beach" />
              <code class="mr-2">f680</code>
              <span>mdi-tower-beach</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tower-fire" />
              <code class="mr-2">f681</code>
              <span>mdi-tower-fire</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-traffic-light" />
              <code class="mr-2">f52b</code>
              <span>mdi-traffic-light</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-train" />
              <code class="mr-2">f52c</code>
              <span>mdi-train</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tram" />
              <code class="mr-2">f52d</code>
              <span>mdi-tram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-transcribe" />
              <code class="mr-2">f52e</code>
              <span>mdi-transcribe</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-transcribe-close" />
              <code class="mr-2">f52f</code>
              <span>mdi-transcribe-close</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-transfer" />
              <code class="mr-2">f530</code>
              <span>mdi-transfer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-transit-transfer" />
              <code class="mr-2">f6ad</code>
              <span>mdi-transit-transfer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-translate" />
              <code class="mr-2">f5ca</code>
              <span>mdi-translate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-treasure-chest" />
              <code class="mr-2">f725</code>
              <span>mdi-treasure-chest</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tree" />
              <code class="mr-2">f531</code>
              <span>mdi-tree</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trello" />
              <code class="mr-2">f532</code>
              <span>mdi-trello</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trending-down" />
              <code class="mr-2">f533</code>
              <span>mdi-trending-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trending-neutral" />
              <code class="mr-2">f534</code>
              <span>mdi-trending-neutral</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trending-up" />
              <code class="mr-2">f535</code>
              <span>mdi-trending-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-triangle" />
              <code class="mr-2">f536</code>
              <span>mdi-triangle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-triangle-outline" />
              <code class="mr-2">f537</code>
              <span>mdi-triangle-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trophy" />
              <code class="mr-2">f538</code>
              <span>mdi-trophy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trophy-award" />
              <code class="mr-2">f539</code>
              <span>mdi-trophy-award</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trophy-outline" />
              <code class="mr-2">f53a</code>
              <span>mdi-trophy-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trophy-variant" />
              <code class="mr-2">f53b</code>
              <span>mdi-trophy-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-trophy-variant-outline" />
              <code class="mr-2">f53c</code>
              <span>mdi-trophy-variant-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-truck" />
              <code class="mr-2">f53d</code>
              <span>mdi-truck</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-truck-delivery" />
              <code class="mr-2">f53e</code>
              <span>mdi-truck-delivery</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-truck-trailer" />
              <code class="mr-2">f726</code>
              <span>mdi-truck-trailer</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tshirt-crew" />
              <code class="mr-2">f53f</code>
              <span>mdi-tshirt-crew</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tshirt-v" />
              <code class="mr-2">f540</code>
              <span>mdi-tshirt-v</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tumblr" />
              <code class="mr-2">f541</code>
              <span>mdi-tumblr</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tumblr-reblog" />
              <code class="mr-2">f542</code>
              <span>mdi-tumblr-reblog</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tune" />
              <code class="mr-2">f62e</code>
              <span>mdi-tune</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-tune-vertical" />
              <code class="mr-2">f66a</code>
              <span>mdi-tune-vertical</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-twitch" />
              <code class="mr-2">f543</code>
              <span>mdi-twitch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-twitter" />
              <code class="mr-2">f544</code>
              <span>mdi-twitter</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-twitter-box" />
              <code class="mr-2">f545</code>
              <span>mdi-twitter-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-twitter-circle" />
              <code class="mr-2">f546</code>
              <span>mdi-twitter-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-twitter-retweet" />
              <code class="mr-2">f547</code>
              <span>mdi-twitter-retweet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ubuntu" />
              <code class="mr-2">f548</code>
              <span>mdi-ubuntu</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-umbraco" />
              <code class="mr-2">f549</code>
              <span>mdi-umbraco</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-umbrella" />
              <code class="mr-2">f54a</code>
              <span>mdi-umbrella</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-umbrella-outline" />
              <code class="mr-2">f54b</code>
              <span>mdi-umbrella-outline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-undo" />
              <code class="mr-2">f54c</code>
              <span>mdi-undo</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-undo-variant" />
              <code class="mr-2">f54d</code>
              <span>mdi-undo-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-unfold-less" />
              <code class="mr-2">f54e</code>
              <span>mdi-unfold-less</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-unfold-more" />
              <code class="mr-2">f54f</code>
              <span>mdi-unfold-more</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-ungroup" />
              <code class="mr-2">f550</code>
              <span>mdi-ungroup</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-unity" />
              <code class="mr-2">f6ae</code>
              <span>mdi-unity</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-untappd" />
              <code class="mr-2">f551</code>
              <span>mdi-untappd</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-update" />
              <code class="mr-2">f6af</code>
              <span>mdi-update</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-upload" />
              <code class="mr-2">f552</code>
              <span>mdi-upload</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-usb" />
              <code class="mr-2">f553</code>
              <span>mdi-usb</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-arrange-above" />
              <code class="mr-2">f554</code>
              <span>mdi-vector-arrange-above</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-arrange-below" />
              <code class="mr-2">f555</code>
              <span>mdi-vector-arrange-below</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-circle" />
              <code class="mr-2">f556</code>
              <span>mdi-vector-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-circle-variant" />
              <code class="mr-2">f557</code>
              <span>mdi-vector-circle-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-combine" />
              <code class="mr-2">f558</code>
              <span>mdi-vector-combine</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-curve" />
              <code class="mr-2">f559</code>
              <span>mdi-vector-curve</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-difference" />
              <code class="mr-2">f55a</code>
              <span>mdi-vector-difference</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-difference-ab" />
              <code class="mr-2">f55b</code>
              <span>mdi-vector-difference-ab</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-difference-ba" />
              <code class="mr-2">f55c</code>
              <span>mdi-vector-difference-ba</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-intersection" />
              <code class="mr-2">f55d</code>
              <span>mdi-vector-intersection</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-line" />
              <code class="mr-2">f55e</code>
              <span>mdi-vector-line</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-point" />
              <code class="mr-2">f55f</code>
              <span>mdi-vector-point</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-polygon" />
              <code class="mr-2">f560</code>
              <span>mdi-vector-polygon</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-polyline" />
              <code class="mr-2">f561</code>
              <span>mdi-vector-polyline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-rectangle" />
              <code class="mr-2">f5c6</code>
              <span>mdi-vector-rectangle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-selection" />
              <code class="mr-2">f562</code>
              <span>mdi-vector-selection</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-square" />
              <code class="mr-2">f001</code>
              <span>mdi-vector-square</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-triangle" />
              <code class="mr-2">f563</code>
              <span>mdi-vector-triangle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vector-union" />
              <code class="mr-2">f564</code>
              <span>mdi-vector-union</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-verified" />
              <code class="mr-2">f565</code>
              <span>mdi-verified</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vibrate" />
              <code class="mr-2">f566</code>
              <span>mdi-vibrate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-video" />
              <code class="mr-2">f567</code>
              <span>mdi-video</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-video-off" />
              <code class="mr-2">f568</code>
              <span>mdi-video-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-video-switch" />
              <code class="mr-2">f569</code>
              <span>mdi-video-switch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-agenda" />
              <code class="mr-2">f56a</code>
              <span>mdi-view-agenda</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-array" />
              <code class="mr-2">f56b</code>
              <span>mdi-view-array</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-carousel" />
              <code class="mr-2">f56c</code>
              <span>mdi-view-carousel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-column" />
              <code class="mr-2">f56d</code>
              <span>mdi-view-column</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-dashboard" />
              <code class="mr-2">f56e</code>
              <span>mdi-view-dashboard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-day" />
              <code class="mr-2">f56f</code>
              <span>mdi-view-day</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-grid" />
              <code class="mr-2">f570</code>
              <span>mdi-view-grid</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-headline" />
              <code class="mr-2">f571</code>
              <span>mdi-view-headline</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-list" />
              <code class="mr-2">f572</code>
              <span>mdi-view-list</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-module" />
              <code class="mr-2">f573</code>
              <span>mdi-view-module</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-parallel" />
              <code class="mr-2">f727</code>
              <span>mdi-view-parallel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-quilt" />
              <code class="mr-2">f574</code>
              <span>mdi-view-quilt</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-sequential" />
              <code class="mr-2">f728</code>
              <span>mdi-view-sequential</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-stream" />
              <code class="mr-2">f575</code>
              <span>mdi-view-stream</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-view-week" />
              <code class="mr-2">f576</code>
              <span>mdi-view-week</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vimeo" />
              <code class="mr-2">f577</code>
              <span>mdi-vimeo</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vine" />
              <code class="mr-2">f578</code>
              <span>mdi-vine</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-violin" />
              <code class="mr-2">f60f</code>
              <span>mdi-violin</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-visualstudio" />
              <code class="mr-2">f610</code>
              <span>mdi-visualstudio</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vk" />
              <code class="mr-2">f579</code>
              <span>mdi-vk</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vk-box" />
              <code class="mr-2">f57a</code>
              <span>mdi-vk-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vk-circle" />
              <code class="mr-2">f57b</code>
              <span>mdi-vk-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vlc" />
              <code class="mr-2">f57c</code>
              <span>mdi-vlc</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-voice" />
              <code class="mr-2">f5cb</code>
              <span>mdi-voice</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-voicemail" />
              <code class="mr-2">f57d</code>
              <span>mdi-voicemail</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-volume-high" />
              <code class="mr-2">f57e</code>
              <span>mdi-volume-high</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-volume-low" />
              <code class="mr-2">f57f</code>
              <span>mdi-volume-low</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-volume-medium" />
              <code class="mr-2">f580</code>
              <span>mdi-volume-medium</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-volume-off" />
              <code class="mr-2">f581</code>
              <span>mdi-volume-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-vpn" />
              <code class="mr-2">f582</code>
              <span>mdi-vpn</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-walk" />
              <code class="mr-2">f583</code>
              <span>mdi-walk</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wallet" />
              <code class="mr-2">f584</code>
              <span>mdi-wallet</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wallet-giftcard" />
              <code class="mr-2">f585</code>
              <span>mdi-wallet-giftcard</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wallet-membership" />
              <code class="mr-2">f586</code>
              <span>mdi-wallet-membership</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wallet-travel" />
              <code class="mr-2">f587</code>
              <span>mdi-wallet-travel</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wan" />
              <code class="mr-2">f588</code>
              <span>mdi-wan</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-washing-machine" />
              <code class="mr-2">f729</code>
              <span>mdi-washing-machine</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-watch" />
              <code class="mr-2">f589</code>
              <span>mdi-watch</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-watch-export" />
              <code class="mr-2">f58a</code>
              <span>mdi-watch-export</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-watch-import" />
              <code class="mr-2">f58b</code>
              <span>mdi-watch-import</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-watch-vibrate" />
              <code class="mr-2">f6b0</code>
              <span>mdi-watch-vibrate</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-water" />
              <code class="mr-2">f58c</code>
              <span>mdi-water</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-water-off" />
              <code class="mr-2">f58d</code>
              <span>mdi-water-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-water-percent" />
              <code class="mr-2">f58e</code>
              <span>mdi-water-percent</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-water-pump" />
              <code class="mr-2">f58f</code>
              <span>mdi-water-pump</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-watermark" />
              <code class="mr-2">f612</code>
              <span>mdi-watermark</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-cloudy" />
              <code class="mr-2">f590</code>
              <span>mdi-weather-cloudy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-fog" />
              <code class="mr-2">f591</code>
              <span>mdi-weather-fog</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-hail" />
              <code class="mr-2">f592</code>
              <span>mdi-weather-hail</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-lightning" />
              <code class="mr-2">f593</code>
              <span>mdi-weather-lightning</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-lightning-rainy" />
              <code class="mr-2">f67d</code>
              <span>mdi-weather-lightning-rainy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-night" />
              <code class="mr-2">f594</code>
              <span>mdi-weather-night</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-partlycloudy" />
              <code class="mr-2">f595</code>
              <span>mdi-weather-partlycloudy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-pouring" />
              <code class="mr-2">f596</code>
              <span>mdi-weather-pouring</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-rainy" />
              <code class="mr-2">f597</code>
              <span>mdi-weather-rainy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-snowy" />
              <code class="mr-2">f598</code>
              <span>mdi-weather-snowy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-snowy-rainy" />
              <code class="mr-2">f67e</code>
              <span>mdi-weather-snowy-rainy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-sunny" />
              <code class="mr-2">f599</code>
              <span>mdi-weather-sunny</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-sunset" />
              <code class="mr-2">f59a</code>
              <span>mdi-weather-sunset</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-sunset-down" />
              <code class="mr-2">f59b</code>
              <span>mdi-weather-sunset-down</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-sunset-up" />
              <code class="mr-2">f59c</code>
              <span>mdi-weather-sunset-up</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-windy" />
              <code class="mr-2">f59d</code>
              <span>mdi-weather-windy</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weather-windy-variant" />
              <code class="mr-2">f59e</code>
              <span>mdi-weather-windy-variant</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-web" />
              <code class="mr-2">f59f</code>
              <span>mdi-web</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-webcam" />
              <code class="mr-2">f5a0</code>
              <span>mdi-webcam</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-webhook" />
              <code class="mr-2">f62f</code>
              <span>mdi-webhook</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-webpack" />
              <code class="mr-2">f72a</code>
              <span>mdi-webpack</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wechat" />
              <code class="mr-2">f611</code>
              <span>mdi-wechat</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weight" />
              <code class="mr-2">f5a1</code>
              <span>mdi-weight</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-weight-kilogram" />
              <code class="mr-2">f5a2</code>
              <span>mdi-weight-kilogram</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-whatsapp" />
              <code class="mr-2">f5a3</code>
              <span>mdi-whatsapp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wheelchair-accessibility" />
              <code class="mr-2">f5a4</code>
              <span>mdi-wheelchair-accessibility</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-white-balance-auto" />
              <code class="mr-2">f5a5</code>
              <span>mdi-white-balance-auto</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-white-balance-incandescent" />
              <code class="mr-2">f5a6</code>
              <span>mdi-white-balance-incandescent</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-white-balance-iridescent" />
              <code class="mr-2">f5a7</code>
              <span>mdi-white-balance-iridescent</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-white-balance-sunny" />
              <code class="mr-2">f5a8</code>
              <span>mdi-white-balance-sunny</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-widgets" />
              <code class="mr-2">f72b</code>
              <span>mdi-widgets</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wifi" />
              <code class="mr-2">f5a9</code>
              <span>mdi-wifi</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wifi-off" />
              <code class="mr-2">f5aa</code>
              <span>mdi-wifi-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wii" />
              <code class="mr-2">f5ab</code>
              <span>mdi-wii</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wiiu" />
              <code class="mr-2">f72c</code>
              <span>mdi-wiiu</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wikipedia" />
              <code class="mr-2">f5ac</code>
              <span>mdi-wikipedia</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-window-close" />
              <code class="mr-2">f5ad</code>
              <span>mdi-window-close</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-window-closed" />
              <code class="mr-2">f5ae</code>
              <span>mdi-window-closed</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-window-maximize" />
              <code class="mr-2">f5af</code>
              <span>mdi-window-maximize</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-window-minimize" />
              <code class="mr-2">f5b0</code>
              <span>mdi-window-minimize</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-window-open" />
              <code class="mr-2">f5b1</code>
              <span>mdi-window-open</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-window-restore" />
              <code class="mr-2">f5b2</code>
              <span>mdi-window-restore</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-windows" />
              <code class="mr-2">f5b3</code>
              <span>mdi-windows</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wordpress" />
              <code class="mr-2">f5b4</code>
              <span>mdi-wordpress</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-worker" />
              <code class="mr-2">f5b5</code>
              <span>mdi-worker</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wrap" />
              <code class="mr-2">f5b6</code>
              <span>mdi-wrap</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wrench" />
              <code class="mr-2">f5b7</code>
              <span>mdi-wrench</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-wunderlist" />
              <code class="mr-2">f5b8</code>
              <span>mdi-wunderlist</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xaml" />
              <code class="mr-2">f673</code>
              <span>mdi-xaml</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xbox" />
              <code class="mr-2">f5b9</code>
              <span>mdi-xbox</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xbox-controller" />
              <code class="mr-2">f5ba</code>
              <span>mdi-xbox-controller</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xbox-controller-off" />
              <code class="mr-2">f5bb</code>
              <span>mdi-xbox-controller-off</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xda" />
              <code class="mr-2">f5bc</code>
              <span>mdi-xda</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xing" />
              <code class="mr-2">f5bd</code>
              <span>mdi-xing</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xing-box" />
              <code class="mr-2">f5be</code>
              <span>mdi-xing-box</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xing-circle" />
              <code class="mr-2">f5bf</code>
              <span>mdi-xing-circle</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-xml" />
              <code class="mr-2">f5c0</code>
              <span>mdi-xml</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-yeast" />
              <code class="mr-2">f5c1</code>
              <span>mdi-yeast</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-yelp" />
              <code class="mr-2">f5c2</code>
              <span>mdi-yelp</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-yin-yang" />
              <code class="mr-2">f67f</code>
              <span>mdi-yin-yang</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-youtube-play" />
              <code class="mr-2">f5c3</code>
              <span>mdi-youtube-play</span>
            </div>
            <div class="m-icon">
              <i class="mr-2 mdi mdi-zip-box" />
              <code class="mr-2">f5c4</code>
              <span>mdi-zip-box</span>
            </div>
          </div>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: 'MaterialIcons',
  data: () => ({
    title: 'MaterialIcons'
  })
};
</script>


